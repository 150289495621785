import {useUserId} from "../../auth/utils";
import {internalDueDate, internalStartDate} from "../../common/common-utils";
import {caseSubActionSort} from "./SubActionsList";
import {
    Button,
    DateTimeInput,
    SaveButton,
    TextInput,
    SimpleForm,
    Toolbar,
    useDataProvider,
    useRecordContext,
    useListContext,
    useNotify,
    BooleanInput,
    useRefresh
} from "react-admin"
import moment from "moment";
import {LazySpinner, SpinnerInline} from "../../utils/Spinner";
import {Alert, Box, Dialog, DialogContent, DialogTitle} from "@mui/material";
import {useEffect, useState} from "react";
import {LoadingButton} from "@mui/lab";
import SaveIcon from '@mui/icons-material/Save';

export const useCompleteSubAction = () => {
    const dataProvider = useDataProvider();
    const data = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    function completeSubAction(caseActionId: string, subActionId: any, completedAt: string, completionNotes: string, userId: string, emailId?: string) {
        setIsLoading(true);
        return dataProvider.getList("case_sub_actions", {
            pagination: {page: 1, perPage: 100},
            sort: caseSubActionSort,
            filter: {
                case_action_id: caseActionId,
                completed_at: {
                    format: "hasura-raw-query",
                    value: {_is_null: true},
                }
            }
        }).then(res => {
            console.log(res);
            const subActions = res.data;
            const currentActionIndex = subActions.findIndex((action: any) => action.id === subActionId);
            const currentAction = subActions[currentActionIndex];
            const nextAction = subActions[currentActionIndex + 1];
            const promises = [];

            // Complete all incomplete sub-actions before and including the current action
            const previousActionsPromises = subActions
                .slice(0, currentActionIndex + 1)
                .filter(action => !action.completed_at) // Only include incomplete actions
                .map(previousAction => dataProvider.update("case_sub_actions", {
                    id: previousAction.id,
                    data: {
                        ...previousAction,
                        completed_at: completedAt,
                        completed_by: userId,
                        completion_notes: completionNotes,
                        ...(emailId ? { email_id: emailId } : {}),
                    },
                    previousData: previousAction,
                }));
            
            promises.push(...previousActionsPromises);

            if (nextAction) {
                const internal_start_date = internalStartDate(nextAction.action_sub_rule, moment(nextAction.case_action.due_date));
                promises.push(dataProvider.update("case_sub_actions", {
                    id: nextAction.id,
                    data: {
                        ...nextAction,
                        internal_start_date,
                        internal_due_date: internalDueDate(nextAction.action_sub_rule, moment(internal_start_date), moment(nextAction.case_action.due_date))
                    },
                    previousData: nextAction,
                }).then(res => {
                    console.log(res);
                    return res;
                }));
            }

            const inactivated = currentAction?.action_sub_rule?.inactivates_action_rule;
            const allCompleted = !nextAction;

            if (inactivated || allCompleted) {
                const inactivetedData = inactivated ? {
                    inactivated_at: completedAt,
                    inactivated_by: userId,
                } : {};
                const completedData = nextAction ? {} : {
                    completed_at: completedAt,
                    completed_by: userId,
                };

                promises.push(dataProvider.update("case_actions", {
                    id: currentAction.case_action_id,
                    data: {
                        ...currentAction.case_action,
                        ...inactivetedData,
                        ...completedData
                    },
                    previousData: currentAction.case_action,
                }).then(res => {
                    console.log(res);
                    return res;
                }));
            }

            return Promise.all(promises).catch((e) => {
                setIsLoading(false);
                throw new Error(e);
            });
        })
    }

    return {data, isLoading, completeSubAction}
}

export const CompleteSubActionButton = (props: any) => {
    const dataProvider = useDataProvider();
    const record = useRecordContext();
    const userId = useUserId();
    const [open, setOpen] = useState(false);
    const [affectedActions, setAffectedActions] = useState<any[]>([]);
    const notify = useNotify();
    const {data, isLoading, completeSubAction} = useCompleteSubAction();
    const refresh = useRefresh();

    // Add this effect to check for affected sub-actions when dialog opens
    useEffect(() => {
        if (open) {
            dataProvider.getList("case_sub_actions", {
                pagination: {page: 1, perPage: 100},
                sort: caseSubActionSort,
                filter: {
                    case_action_id: record.case_action_id,
                    completed_at: {
                        format: "hasura-raw-query",
                        value: {_is_null: true},
                    }
                }
            }).then(res => {
                const subActions = res.data;
                const currentActionIndex = subActions.findIndex((action: any) => action.id === record.id);
                const incompletePreviewsActions = subActions
                    .slice(0, currentActionIndex)
                    .filter(action => !action.completed_at);
                setAffectedActions(incompletePreviewsActions);
            });
        }
    }, [open, record.id, record.case_action_id]);

    const onSubmit = (formData: any) => {
        // First fetch next sub action, then update current and next sub action
        console.log(record);
        completeSubAction(record.case_action_id, record.id, moment().format(), "", userId)
            .then(() => {
                notify(`Task successfully marked as completed`, {type: 'success'});
                refresh && refresh();
            });
    }
    const close = (e: any) => {
        e?.preventDefault();
        setOpen(false)
    };
    console.log(record);
    const buttonText = record?.action_sub_rule?.complete_button_text || "Mark completed";

    return (
        <>
            <Button
                label={buttonText}
                onClick={() => setOpen(true)}
            >
                <></>
            </Button>
            <Dialog open={open} onClose={close} fullWidth maxWidth={"md"}>
                <DialogTitle>Mark task as completed</DialogTitle>
                <DialogContent sx={{minWidth: "600px"}}>
                    <SimpleForm
                        onSubmit={onSubmit}
                        toolbar={
                            <Toolbar>
                                {/*<SaveButton label="Mark completed" alwaysEnable />*/}
                                <LoadingButton
                                    loading={isLoading}
                                    loadingPosition="start"
                                    startIcon={<SaveIcon/>}
                                    variant="contained"
                                    type={"submit"}
                                >
                                    {buttonText}
                                </LoadingButton>
                            </Toolbar>
                        }>
                        <Box width={"100%"} maxWidth={"400px"}>
                            <DateTimeInput source={"completed_at"} defaultValue={moment().format()}
                                           parse={(val: any) => moment(val).format()}></DateTimeInput>
                            <TextInput source={"completion_notes"} multiline fullWidth></TextInput>
                            {affectedActions.length > 0 && (
                                <Alert
                                    severity="info"
                                    sx={{ mb: 2 }}
                                >
                                    {`${affectedActions.length} previous incomplete ${
                                        affectedActions.length === 1 ? 'task' : 'tasks'
                                    } will also be marked as completed.`}
                                </Alert>
                            )}
                        </Box>
                    </SimpleForm>
                </DialogContent>
                {/*<DialogActions>*/}
                {/*    <Button onClick={close} label={"Cancel"}></Button>*/}
                {/*    <Button onClick={sendEmail} label={"Send email"}></Button>*/}
                {/*</DialogActions>*/}
            </Dialog>
        </>
    );
}