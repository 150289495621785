import { useAuth0 } from '@auth0/auth0-react';


export const tenantIdFromAuth0Data = (auth0Data: any) => auth0Data.user?.["https://hasura.io/jwt/claims"]?.["x-hasura-tenant-id"];

export const useTenantId = (): string | null | undefined => {
    const auth0Data = useAuth0<any>();

    return tenantIdFromAuth0Data(auth0Data);
}
