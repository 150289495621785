
export const ANNUITY = "Annuity";
export const RENEWAL = "Renewal";
const actionTypes = [ANNUITY, RENEWAL, "Substantive office action", "Formality", "Convention date", "Post PCT", "Other"];
export const actionTypeOptions = actionTypes.map((type) => ({ id: type, name: type}))

export const CORPORATE_TYPE = "corporate";
export const AGENT_TYPE = "agent";
export const ACTION_RULE_ALL_TYPE = "all";
export const actionTenantTypeOptions = [
    { id: CORPORATE_TYPE, name: 'Corporate' },
    { id: AGENT_TYPE, name: 'Agent' },
    { id: ACTION_RULE_ALL_TYPE, name: 'All' },
];

