import * as React from 'react';
import { HorizontalMenu } from '@react-admin/ra-navigation';
import {useIsAdmin} from "../auth/utils";
import {Link, Logout, UserMenu, useUserMenu, Button, ToggleThemeButton } from 'react-admin';
import { MenuList, MenuItem, ListItemIcon, ListItemText, Tab, Menu as MUIMenu } from '@mui/material';
import SettingsIcon from '@mui/icons-material/Settings';
import {FunctionComponent, ReactHTML, useState} from 'react';
import {TenantContext} from "./AppLayout";
export const Menu = () => {
    const isAdmin = useIsAdmin();
    const { tenant } = React.useContext(TenantContext) || {};

    const documents = tenant?.feature_documents;
    const trademarks = tenant?.feature_trademarks;
    const designs = tenant?.feature_designs;
    const other_cases = tenant?.feature_other_cases;
    const actions = tenant?.feature_actions;
    const tasks = tenant?.feature_tasks;
    const charging = tenant?.feature_charging;

    return (
        <HorizontalMenu>
            <HorizontalMenu.Item label="Home"  value="home" to={"/"} />
            {/*<HorizontalMenu.Item label="Cases"  value="cases" />*/}
            <HorizontalMenu.Item label="Patents"  value="patents" />
            {/*{ isAdmin ? <HorizontalMenu.Item label="Trademarks"  value="trademarks" to={"/trademarks"} /> : <></> }*/}
            { trademarks ? <HorizontalMenu.Item label="Trademarks"  value="trademarks" to={"/trademarks"} /> : <></> }
            { designs ? <HorizontalMenu.Item label="Designs"  value="designs" to={"/designs"} /> : <></> }
            {/*<HorizontalMenu.Item label="Other cases"  value="other-cases" to={"/other-cases"} />*/}
            { other_cases ? <OtherCasesMenu label="Other cases"  value="other-cases" to={"/other-cases"}></OtherCasesMenu> : <></> }
            {/*<Link label="Other cases"  value="other-cases" to={"/javascript:void(0)"}></Link>*/}
            <HorizontalMenu.Item label="Annuities"  value="annuities" />
            {/*{isAdmin ? <HorizontalMenu.Item label="Actions"  value="actions" /> : <></>}*/}
            { actions ? <HorizontalMenu.Item label="Actions"  value="actions" /> : <></> }
            {/*{isAdmin ? <HorizontalMenu.Item label="Tasks"  value="tasks" /> : <></>}*/}
            { tasks ? <HorizontalMenu.Item label="Tasks"  value="tasks" /> : <></> }
            { charging ? <HorizontalMenu.Item label="Charging"  value="charges" /> : <></> }
            {/*{*/}
            {/*    isAdmin ?*/}
            {/*        [*/}
            {/*            <HorizontalMenu.Item label="Users" value="users" />,*/}
            {/*            <HorizontalMenu.Item label="Names" value="names" />,*/}
            {/*            <HorizontalMenu.Item label="Teams" value="case_teams" />,*/}
            {/*            <HorizontalMenu.Item label="Clients"  value="tenants" />,*/}
            {/*            <HorizontalMenu.Item label="Docketing"  value="docketing" />,*/}
            {/*            <HorizontalMenu.Item label="Orders"  value="orders" />,*/}
            {/*            // <ToggleThemeButton />,*/}
            {/*        ] : []*/}
            {/*}*/}
            { isAdmin ? <AdminMenu /> : <></> }
        </HorizontalMenu>
    )
};

export const CustomUserMenu = () => {
    const isAdmin = useIsAdmin();
    return (
        <UserMenu>
            <MenuList>
                { isAdmin && <CustomMenuItem to={"/ep_orders"} label={"Orders: EP"} />}
                { isAdmin && <CustomMenuItem to={"/pct_orders"} label={"Orders: PCT"} />}
                { isAdmin && <CustomMenuItem to={"/instructions_received"} label={"Orders: Annuity/Renewal"} />}
                { isAdmin && <CustomMenuItem to={"/action_rules"} label={"Action rules"} /> }
                { isAdmin && <CustomMenuItem to={"/country_rules"} label={"Country Rules"} />}
                { isAdmin && <CustomMenuItem to={"/prices"} label={"Prices"} />}
                { isAdmin && <CustomMenuItem to={"/exchange_rates"} label={"Exchange Rates"} />}
                {/*{ isAdmin && <CustomMenuItem to={"/orders"} label={"PCT/EP Orders"} />}*/}
                { isAdmin && <CustomMenuItem to={"/email_templates"} label={"Email templates"} />}
                { isAdmin && <CustomMenuItem to={"/legal_event_country_codes"} label={"Legal Event Codes"} />}
                { isAdmin && <CustomMenuItem to={"/docketing_test_cases"} label={"Docketing Test Cases"} />}
                { isAdmin && <CustomMenuItem to={"/ep_validation_country_prices"} label={"EP Validation Prices"} />}
                { isAdmin && <CustomMenuItem to={"/pct_country_prices"} label={"PCT Prices"} />}
                { isAdmin && <CustomMenuItem to={"/import_sessions"} label={"Import Sessions"} />}
                { isAdmin && <CustomMenuItem to={"/import-from-file"} label={"Import from file"} />}
                { isAdmin && <CustomMenuItem to={"/annuity_cost_forecasts"} label={"Annuity Cost Forecast"} />}
                { isAdmin && <CustomMenuItem to={"/content"} label={"Content"} />}
                <Logout />
            </MenuList>
        </UserMenu>
    )
};

const CustomMenuItem: React.FunctionComponent<{ to: string, label: string }> = React.forwardRef(({ to, label }, ref) => {
    const { onClose } = useUserMenu();
    return (
        <MenuItem
            ref={ref}
            sx={{ color: 'text.secondary' }}
            to={to}
            component={Link}
            onClick={onClose}
        >
            <ListItemText>{label}</ListItemText>
        </MenuItem>
    );
});

const otherCasesMenuItems = [
    {label: "Infringement", to: "/other-cases/infringement"},
    {label: "Opposition", to: "/other-cases/opposition"},
];
const OtherCasesMenu =  (props: any) => {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        event?.preventDefault();
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    return (<>
            <HorizontalMenu.Item
                value={"other-cases"}
                component={Link}
                id="basic-button"
                aria-controls={open ? 'basic-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                // @ts-ignore
                onClick={handleClick}
                color="inherit"
                label={"Other Cases"}
                to={"javascript:void(0)"}
            >
            </HorizontalMenu.Item>
            <MUIMenu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                closeAfterTransition
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
            >
                {/*<MenuItem onClick={handleClose} to={"/other-cases/design"} component={Link}>Design</MenuItem>*/}
                {/*<MenuItem onClick={handleClose} to={"/other-cases/infringement"} component={Link}>Infringements</MenuItem>*/}
                {/*<MenuItem onClick={handleClose} to={"/other-cases/opposition"} component={Link}>Oppositions</MenuItem>*/}
                {otherCasesMenuItems.map((item) => <MenuItem onClick={handleClose} to={item.to} component={Link}>{item.label}</MenuItem>)}
            </MUIMenu>
    </>
    );
}

const AdminMenu =  (props: any) => {
    const { tenant } = React.useContext(TenantContext) || {};
    const trademarks = !tenant?.feature_trademarks;
    const designs = !tenant?.feature_designs;
    const other_cases = !tenant?.feature_other_cases;
    const actions = !tenant?.feature_actions;
    const tasks = !tenant?.feature_tasks;
    const charging = !tenant?.feature_charging;

    const featureBasedMenuItems = [
        actions ? [{label: "Actions", to: "/actions"}] : [],
        tasks ? [{label: "Tasks", to: "/tasks"}] : [],
        trademarks ? [{label: "Trademarks", to: "/trademarks"}] : [],
        designs ? [{label: "Designs", to: "/designs"}] : [],
        other_cases ? [...otherCasesMenuItems] : [],
        charging ? [{label: "Charging", to: "/charges"}] : [],
    ].flat();

    return (
        <DropDownMenu label={"Admin"} value={"admin"} items={[
            ...[{label: "Users", to: "/users"},
                {label: "Names", to: "/names"},
                {label: "Teams", to: "/case_teams"},
                {label: "Clients", to: "/tenants"},
                {label: "Docketing", to: "/docketing"},
            ],
            ...featureBasedMenuItems,
        ]}/>
    );
}
type MenuItem = {
    label: string;
    to: string;
}
const DropDownMenu: FunctionComponent<{value: string, label: string, items: Array<MenuItem> }> =  ({value, label, items}) => {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        event?.preventDefault();
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    return (<>
            <HorizontalMenu.Item
                value={value}
                component={Link}
                id="basic-button"
                aria-controls={open ? 'basic-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                // @ts-ignore
                onClick={handleClick}
                color="inherit"
                label={label}
                to={"javascript:void(0)"}
            >
            </HorizontalMenu.Item>
            <MUIMenu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                closeAfterTransition
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
            >
                {items.map((item) => <MenuItem onClick={handleClose} to={item.to} component={Link}>{item.label}</MenuItem>)}
            </MUIMenu>
        </>
    );
}


