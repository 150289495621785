import {NextButton, PreviousButton, useWizardFormContext, WizardForm, WizardToolbarProps} from "@react-admin/ra-form-layout";
import * as React from "react";
import {
    ArrayInput,
    AutocompleteInput,
    BooleanInput,
    CheckboxGroupInput,
    Create,
    Datagrid,
    FormDataConsumer,
    FunctionField,
    Labeled,
    Link,
    NumberField,
    ReferenceInput,
    required,
    SaveButton,
    SelectInput,
    Button,
    ShowBase,
    SimpleFormIterator,
    TextField,
    TextInput,
    useDataProvider,
    useGetList,
    useRecordContext,
    useSaveContext,
    WithRecord,
    WrapperField
} from "react-admin";
import {useIsAdmin, useUserId} from "../../auth/utils";
import {Page} from "../../utils/Page";
import {
    allCountries,
    countries,
    countriesToOptions,
    epStates,
    pctStates,
    unitaryPatentCountries
} from "../../utils/countries";
import {Box, Grid, Stack, Toolbar as MUIToolbar, Tooltip, Typography} from "@mui/material";
import { useLocation, useParams } from "react-router";
import {
    CountryFlagField,
    countryOption, CurrencyField,
    LocalAgentField,
    LocalAgentInput,
    SelectCountriesWizardToolbar
} from "./common";
import {noRefetch, useTenantContext} from "../../react-admin-overrides/AppLayout";
import {ApplicationNumberField} from "../crud/AppplicationNumberField";
import {roundToTwoDecimals} from "../../common/common-utils";
import {FieldValues, useWatch } from "react-hook-form";
import {dateFormat} from "../annuities/Annuities";
import { useEffect } from "react";
import {submitOrdersMutation} from "./order_mutation";
import {useHasuraRequest} from "../../utils/useHasuraRequest";
import {Spinner} from "../../utils/Spinner";
import {ErrorDialog} from "../../utils/ErrorDialog";
import {SuccessDialog} from "../../utils/SuccessDialog";
import HelpIcon from '@mui/icons-material/HelpOutline';
import DownloadIcon from '@mui/icons-material/GetApp';
import {EPPricesExportToExcel} from "./ep-prices-exporter";
import {CaseLink} from "../crud/CaseRefField";

const calcTranslationFee = (caseRecord: any, priceEntry: any): number => {
    const translation_requirements = priceEntry.translation_requirements;
    const translation_fee_per_word = priceEntry.translation_fee_per_word;

    if (translation_requirements === "Claims") return caseRecord.word_count_claims_only * translation_fee_per_word;
    if (translation_requirements === "Application and Claims") return caseRecord.word_count * translation_fee_per_word;

    return 0;
};

const priceCalc = (caseRecord: any, priceTable: Array<any>) => (countryCode: string)  => {
    const priceEntry = priceTable.find((p: any) => p.country_code === countryCode);
    const serviceFees = Math.ceil((priceEntry?.our_fee || 0) + (priceEntry?.local_agent_fee || 0));
    const translationFee = Math.ceil(calcTranslationFee(caseRecord, priceEntry));
    const officialFees = Math.ceil(priceEntry?.official_fee_eur);
    const total = officialFees + serviceFees + translationFee;
    return {
        official_fees: officialFees,
        service_fees: serviceFees,
        translation_fees: translationFee,
        total,
    }
};

export const defaultAgentIdFunction = (priceTable: Array<any>) => (countryCode: string) => {
    const priceEntry = priceTable.find((p: any) => p.country_code === countryCode);
    return priceEntry?.default_agent_id;
}
export const switzerlandLichtenstein = (countryCode: string, designatedStates: Array<string>) => {
    if (countryCode === "ch-li") return designatedStates.includes("CH") || designatedStates.includes("LI");

    return false;
}

export const SelectCountriesEP = (props: any) => {
    const dataProvider = useDataProvider();
    const caseRecord = useRecordContext();
    const { loading: isSubmittingOrder, data: orderData, error: orderError, makeApiCall } = useHasuraRequest();
    const userId = useUserId();
    const { tenant } = useTenantContext()  || {};

    const { data, isLoading, error } = useGetList('ep_validation_country_prices', {
        pagination: { perPage: 10000, page: 1 },
    }, noRefetch);

    const { data: eurData } = useGetList('exchange_rates', {
        pagination: { perPage: 1, page: 1 },
        filter: { currency_code: "EUR" },
        sort: { field: "date", order: "DESC" }
    }, noRefetch);

    const { data: usdData } = useGetList('exchange_rates', {
        pagination: { perPage: 1, page: 1 },
        filter: { currency_code: "USD" },
        sort: { field: "date", order: "DESC" }
    }, noRefetch);

    if (!caseRecord) return null;
    if (!data) return null;
    if (!eurData) return null;
    if (!usdData) return null;

    console.log(eurData);

    // const oneEURToNOK = 1 / eurData[0].exchange_rate;
    const oneEURToNOK = (1 / eurData[0].exchange_rate) * 1.05;
    // const oneEURToUSD = oneEURToNOK * usdData[0].exchange_rate;
    const oneEURToUSD = (oneEURToNOK * usdData[0].exchange_rate) * 1.05;
    const currencyConvert = { currency_code: tenant?.currency_code || "USD", factor: tenant?.currency_code === "NOK" ? oneEURToNOK : oneEURToUSD };


    const designatedStates = caseRecord.designated_states?.split(" ");
    console.log(caseRecord.designated_states);
    const choices = designatedStates ?
        countriesToOptions(epStates).filter(country => designatedStates.includes(country.id.toUpperCase()) || switzerlandLichtenstein(country.id, designatedStates)) :
        countriesToOptions(epStates);

    const countryPriceCalc = priceCalc(caseRecord, data);
    const defaultAgentId = defaultAgentIdFunction(data);

    const onSubmit = (formData: any) => {
        console.log("SUBMIT", formData);
        const countriesDatagrid = createCountriesDatagrid(formData, countryPriceCalc, defaultAgentId)
        const totalPrice = sumField(countriesDatagrid, "total");

        const ep_order_lines = (formData.countries || []).map((country_code: string) => {
            const priceConfig = countriesDatagrid.find((record: any) => record.country_code === country_code);
            const prices = priceConfig ? {
                price: priceConfig.total,
                price_currency: "EUR",
                translation_fee: priceConfig.translation_fees,
                service_fee: priceConfig.service_fees,
                official_fee: priceConfig.official_fees,
            } : {};
            return {
                country_code,
                agent_id: formData[country_code]?.agent,
                ...prices
            };
        });

        const order = {
            tenant_id: tenant?.id,
            created_by: userId,
            order_type: "EP",
            portal_price: totalPrice,
            ep_orders: {
                data: {
                    case_id: caseRecord.id,
                    user_reference: formData.your_reference,
                    user_comments: formData.comments,
                    ep_order_lines: {
                        data: ep_order_lines
                    }
                }
            }
        }
        console.log("ORDER", order);

        makeApiCall({
            query: submitOrdersMutation,
            variables: {
                orders: [order]
            }
        });
    }

    const parse = (countries: any) => {
        const containsUP = countries?.includes("up");
        const newVar = containsUP ? countries.filter((c: any) => !unitaryPatentCountries.includes(c)) : countries;
        console.log("parse", countries, containsUP, newVar);
        return newVar;
    };

    return (
        <Page title={`Validate EP Patent: EP${caseRecord.registration_number || caseRecord.application_number}`} center body={
            <Stack my={2} textAlign={"center"} alignItems={"center"}>
                <Stack direction={"row"} spacing={1} divider={<span>–</span>}>
                    { caseRecord.application_number ? <ApplicationNumberField /> : null}
                    { caseRecord.tenant_case_ref ? <CaseLink caseId={caseRecord.id as string} linkText={caseRecord.tenant_case_ref} target={"_blank"}/> : null }
                    { caseRecord.case_ref ? <CaseLink caseId={caseRecord.id as string} linkText={caseRecord.case_ref} target={"_blank"}/> : null }
                </Stack>
                { caseRecord.invention_title && <Box>{caseRecord.invention_title}</Box> }
                { caseRecord.applicant && <Box>{caseRecord.applicant}</Box> }
                {/*<Tooltip title=></CaseImagePreview>} arrow>*/}
                {/*    <span>{caseRecord.application_number}</span>*/}
                {/*</Tooltip>*/}
            </Stack>
        }>
            {
                isSubmittingOrder && <Spinner></Spinner>
            }
            {
                orderError &&
                <ErrorDialog ></ErrorDialog>
            }

            {
                orderData &&
                <SuccessDialog></SuccessDialog>
            }
            <Box maxWidth={"lg"} >
                <Create>
                    <WizardForm
                        toolbar={<SelectCountriesWizardToolbar />}
                        onSubmit={onSubmit}
                    >
                        <WizardForm.Step label="Select countries">
                            <Stack>
                                <Stack spacing={1} marginBottom={2}>
                                    <Box>You have received the communication “Decision to grant a European patent” stating that the mention of grant will be published in the European Patent Bulletin. This means that the patent has to be validated/registered in your selection of countries within 3 months after the grant date.</Box>
                                    <Box>The easiest way to validate your patent is to select countries below and proceed to “send validation order” and Breeze IP will take care of the validation, i.e. appoint local agents, file translations and Power of Attorney document, and register a local agent as your representative.</Box>
                                    <Box>We will send you one package of documents to be signed, one invoice and one filing report.</Box>
                                </Stack>
                                <h4>Unitary Patent</h4>
                                <Typography variant="caption">
                                    <Box>Unitary patents covers the following states:</Box>
                                    <Box>{unitaryPatentCountries.map(c => epStates[c]).join(", ")}</Box>
                                </Typography>
                                <CheckboxGroupInput
                                    source="countries"
                                    choices={[{id: "up", name: `${epStates["up"]}`}]}
                                    optionText={countryOption}
                                    sx={{ '& .MuiFormControlLabel-root': { width: 280 } }}
                                    row={true}
                                    parse={parse}
                                />
                                <h4>Other validation states</h4>
                                <EPCountriesInput choices={choices}></EPCountriesInput>
                                <FormDataConsumer>
                                    {({ formData, ...rest }) => {
                                        console.log(formData);
                                        const countriesDatagrid = formData.countries?.map((code: string) => ({
                                            country_code: code,
                                            name: countries[code],
                                            ...countryPriceCalc(code),
                                        }));

                                        if (!countriesDatagrid || countriesDatagrid.length === 0) return null;

                                        const inkSumLine = [...countriesDatagrid, { name: "Sum", total: sumField(countriesDatagrid, "total")}]
                                        return (
                                            <div>
                                                <Stack direction={"row"} justifyContent={"flex-end"} my={2}>
                                                    <Button
                                                        label={`Export ${currencyConvert.currency_code} Prices`}
                                                        onClick={() => EPPricesExportToExcel(countriesDatagrid, caseRecord, currencyConvert, tenant?.ep_fee)}
                                                        endIcon={<DownloadIcon></DownloadIcon>}
                                                    />
                                                </Stack>
                                                <Datagrid data={inkSumLine} sort={{ field: "name", order: "ASC" }}>
                                                    <CountryFlagField source="name" countryCodeSource={"country_code"} label={"Country"} />
                                                    <CurrencyField source={"official_fees"} currency={"EUR"}></CurrencyField>
                                                    <CurrencyField source={"service_fees"} currency={"EUR"}></CurrencyField>
                                                    <CurrencyField source={"translation_fees"} currency={"EUR"}></CurrencyField>
                                                    <CurrencyField label={"Total costs"} source={"total"} currency={"EUR"} convert={currencyConvert}/>
                                                    {/*<FunctionField label={"Total costs"} render={(record: any) => (*/}
                                                    {/*    <span>{record.official_fees + record.service_fees + record.translation_fees}</span>*/}
                                                    {/*)}/>*/}
                                                    {/*<SelectInput source="agent" choices={[{id: "1", name: "Agent 1"}, {id: "2", name: "Agent 2"}]}/>*/}
                                                </Datagrid>
                                            </div>
                                        )
                                    }
                                    }
                                </FormDataConsumer>
                            </Stack>
                        </WizardForm.Step>
                        <WizardForm.Step label="Select Agents">
                            {
                                <FormDataConsumer>
                                    {({ formData, ...rest }) => {
                                        console.log(formData);
                                        const countriesDatagrid = formData.countries?.map((code: string) => ({
                                            country_code: code,
                                            name: countries[code],
                                            ...countryPriceCalc(code),
                                            defaultAgentId: defaultAgentId(code)
                                        }));

                                        if (!countriesDatagrid || countriesDatagrid.length === 0) return null;

                                        return (
                                            <div>
                                                <p>Please select the local agents you want to use.</p>
                                                <p>The costs will increase if you choose to use agents which we do not have an price agreement with.</p>
                                                <Datagrid data={countriesDatagrid} sort={{ field: "name", order: "ASC" }}>
                                                    <CountryFlagField source="name" countryCodeSource={"country_code"} label={"Country"} />
                                                    <CurrencyField label={"Total costs"} source={"total"} currency={"EUR"} convert={currencyConvert}/>
                                                    <LocalAgentInput
                                                        countryCodeSource={"country_code"}
                                                        required={true}
                                                        validate={required()}
                                                        defaultValueSource={"defaultAgentId"}
                                                    ></LocalAgentInput>
                                                </Datagrid>
                                            </div>
                                        )
                                    }
                                    }
                                </FormDataConsumer>
                            }
                        </WizardForm.Step>
                        <WizardForm.Step label="Review & submit">
                            {/*<TextInput source="fullDescription" validate={required()}/>*/}

                            {
                                <FormDataConsumer>
                                    {({ formData, ...rest }) => {
                                        console.log(formData);
                                        const countriesDatagrid = formData.countries?.map((code: string) => ({
                                            country_code: code,
                                            name: countries[code],
                                            ...countryPriceCalc(code)
                                        }));

                                        if (!countriesDatagrid || countriesDatagrid.length === 0) return null;

                                        const inkSumLine = [...countriesDatagrid, { name: "Sum", total: sumField(countriesDatagrid, "total")}]
                                        return (
                                            <Box>
                                                <Box my={2}>Please check the order, accept Breeze IP’s terms and conditions, and send the order.</Box>
                                                <Stack direction={"row"} justifyContent={"flex-end"}>
                                                    <Button
                                                        label={`Export ${currencyConvert.currency_code} Prices`}
                                                        onClick={() => EPPricesExportToExcel(countriesDatagrid, caseRecord, currencyConvert, tenant?.ep_fee)}
                                                        endIcon={<DownloadIcon></DownloadIcon>}
                                                    />
                                                </Stack>
                                                <Datagrid data={inkSumLine} sort={{ field: "name", order: "ASC" }}>
                                                    <CountryFlagField source="name" countryCodeSource={"country_code"} label={"Country"} />
                                                    <LocalAgentField countryCodeSource={"country_code"} label={"Agent"} ></LocalAgentField>
                                                    <CurrencyField label={"Total costs"} source={"total"} currency={"EUR"} convert={currencyConvert}/>
                                                    {/*<SelectInput label={"Local Agent"} source="agent" choices={[{id: "1", name: "Agent 1"}, {id: "2", name: "Agent 2"}]}/>*/}
                                                </Datagrid>
                                                <Box marginTop={4} maxWidth={400}>
                                                    <Datagrid data={[
                                                        { count_name: "Number of words in application", count: caseRecord.word_count || "N/A" },
                                                        { count_name: "Number of words in claims", count: caseRecord.word_count_claims_only || "N/A" },
                                                        { count_name: "Number of claims", count: caseRecord.claims_count || "N/A" },
                                                        { count_name: "Number of independent claims", count: caseRecord.independent_claims_count || "N/A" },
                                                        { count_name: "Number of pages in application", count: caseRecord.page_count || "N/A" },
                                                    ]} sort={{ field: "name", order: "ASC" }}>
                                                        <TextField source="count_name" label={<strong>Relevant counts</strong>}/>
                                                        <TextField source="count" label={false} textAlign={"right"}/>
                                                    </Datagrid>
                                                </Box>
                                                <Box marginTop={4} maxWidth={400}>
                                                    <Typography variant="body2" component="div" sx={{ maxWidth: "640px" }}>
                                                        <div>If you have a reference number or additional comments, please enter them below.</div>
                                                        {/*<div>({caseRecord.case_ref} is the reference number for this case in Breeze IP portal)</div>*/}
                                                    </Typography>
                                                    <TextInput source="your_reference" fullWidth defaultValue={caseRecord.case_ref}></TextInput>
                                                    <TextInput source="comments" multiline fullWidth minRows={3}></TextInput>
                                                </Box>
                                                <Box marginTop={4}>
                                                    <BooleanInput label={<span>I accept Breeze IP's <Link to={"/terms-ep"} target={"_blank"}>terms and conditions</Link></span>} source="terms_accepted" />
                                                </Box>
                                            </Box>
                                        )
                                    }
                                    }
                                </FormDataConsumer>
                            }
                        </WizardForm.Step>
                    </WizardForm>
                </Create>
            </Box>
        </Page>
    );
};

const EPCountriesInput = (props: any) => {
    const countries = useWatch<{ countries: any }>({ name: "countries" });
    console.log("watch", countries);
    const containsUP = countries?.includes("up");
    const choices = containsUP ? props.choices.filter((c: any) => !unitaryPatentCountries.includes(c.id)) : props.choices;

    return (
        <CheckboxGroupInput
            source="countries"
            choices={choices}
            optionText={countryOption}
            sx={{ '& .MuiFormControlLabel-root': { width: 280 } }}
            row={true}
        />
    );
}

const sumField = (array: Array<any>, fieldName: string): number => array.reduce((previousValue, currentValue, currentIndex, array) => {
    const field = currentValue[fieldName];
    const numberValue = field ? Number(field) : 0;
    const safeNumber = isNaN(numberValue) ? 0 : numberValue;
    console.log(previousValue, currentValue, field, numberValue, safeNumber);
    return previousValue + safeNumber;
}, 0);

const CaseImagePreview = (props: any) => {
    const caseRecord = useRecordContext(props);
    const [data, setData] = React.useState<any>(null);
    useEffect(() => {
        const kind = "A1"
        fetch(`${process.env.REACT_APP_FUNCTIONS_URL}/epoFirstPage?applicationNumber=${(caseRecord?.country_code as string || "").toUpperCase()}${caseRecord?.registration_number}&kind=${kind}`)
            .then((res) => res.text())
            .then((text) => setData(text));

    }, [caseRecord?.application_number]);

    if (!caseRecord || !data) return null;
    console.log(data);
    return (
        <img src={`, ${data}`} />
    );
}

const createCountriesDatagrid = (formData: FieldValues, countryPriceCalc: any, defaultAgentId: any) => formData.countries?.map((code: string) => ({
    country_code: code,
    name: allCountries[code],
    ...countryPriceCalc(code, formData[code]?.applicant_employees),
    defaultAgentId: defaultAgentId(code)
}));

