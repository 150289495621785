import {Flag} from "../utils/Flag";
import {countries} from "../utils/countries";
import {useRecordContext, WithRecord } from "react-admin";
import { ShowInDialogButton } from "@react-admin/ra-form-layout";
import { Box } from "@mui/system";
import { Card, CardContent } from "@mui/material";


export const ShowContentInDialogButton = (props: any) => {
    const record = useRecordContext();
    const id = props?.id || record?.content_id;
    if (!id) return null;

    return (
        <ShowInDialogButton
            resource={"content"}
            id={id}
            fullWidth
            maxWidth="md"
            label={props?.label || "View Content"}
            icon={<></>}
            title={
                <WithRecord render={(record: any) => (
                    <Box>
                        {record?.title}
                    </Box>
                )}/>
            }
        >
            <WithRecord render={(record: any) => (
                <Card>
                    <CardContent>
                        <div dangerouslySetInnerHTML={{__html: record?.html_content}}></div>
                    </CardContent>
                </Card>
            )}/>

        </ShowInDialogButton>
    )
}