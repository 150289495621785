import * as React from "react";
import {
    AutocompleteInput,
    BulkDeleteButton,
    Count,
    Datagrid,
    DateField,
    Filter,
    FunctionField,
    Link,
    List,
    ReferenceField,
    ReferenceInput,
    ReferenceOneField,
    SelectInput,
    SingleFieldList,
    TextField,
    useRecordContext,
    WithRecord
} from 'react-admin';
import {useIsAdmin} from "../auth/utils";
import {Flag} from "../utils/Flag";
import {countries, euFlagCountries} from "../utils/countries";
import {Box, Stack} from "@mui/system";
import moment from "moment";
import {CaseRefField} from "../cases/crud/CaseRefField";
import { Tabs, Tab } from "@mui/material";
import {INSTRUCTION_WAIT} from "../cases/annuities/Annuities";
import {HANDLER_BREEZE} from "../cases/instructions/SetBreezeAsHandlerButton";
import {SendOrderConfirmationEmailButton} from "./SendOrderConfirmationEmailButton";
import {SendAgentInstructionsEmailButton} from "./SendAgentInstructionsEmailButton";
import { Page } from "../utils/Page";
import { FlagField } from "../utils/FlagField";
import {emojis} from "../utils/utils";
import {MarkConfirmationFromAgentReceived} from "./MarkConfirmationFromAgentReceived";
import {
    AnnuityAbandonedField,
    AnnuityPaidElsewhereField,
    AnnuityPaidField,
    InstructionsConfirmedField,
    InstructionsSentField, InstructionsWaitField
} from "../cases/annuities/utils";
import {dateFormat} from "../cases/actions/Actions";
import {MarkDocsFromAgentReceived} from "./MarkDocsFromAgentReceived";
import {MarkDocsFromClientReceived} from "./MarkDocsFromClientReceived";
import {MarkInvoiceSent} from "../cases/instructions/MarkInvoiceSent";
import {orderNumber} from "./orders";
import {HappyEmpty} from "../utils/HappyEmpty";
import {OrderLineStatus} from "./OrderLineStatus";
import {dataProviderFilters} from "../utils/dataProviderFilters";
import {ANNUITY, RENEWAL} from "../cases/actions/utils";
import {PatentsExpanded} from "./EPOrders";

const PCTOrderFilters = (props: any) => {
    const isAdmin = useIsAdmin();
    return (
        <Filter {...props} >
            <ReferenceInput source="order#tenant_id" reference="tenants" alwaysOn>
                <AutocompleteInput filterToQuery={(searchText: string) => ({name: `${searchText}`})} label="Client"
                                   fullWidth/>
            </ReferenceInput>
            <ReferenceInput label="Order" reference="orders" source="order_id" >
                <AutocompleteInput filterToQuery={(searchText: string) => ({id: `${searchText}`})} label="Order"
                                   optionText={(record: any) => `${record.order_type} – ${moment(record.created_at).format("DD.MM.YY HH:mm")}`}
                                   fullWidth/>
            </ReferenceInput>
        </Filter>
    );
};

const PCTOrderLineFilters = (props: any) => {
    const isAdmin = useIsAdmin();
    return (
        <Filter {...props}>
            <ReferenceInput source="pct_order#order#tenant_id" reference="tenants" alwaysOn>
                <AutocompleteInput filterToQuery={(searchText: string) => ({name: `${searchText}`})} label="Client"
                                   fullWidth/>
            </ReferenceInput>
            <ReferenceInput label="Order" reference="orders" source="pct_order#order_id" >
                <AutocompleteInput filterToQuery={(searchText: string) => ({id: `${searchText}`})} label="Order"
                                   optionText={(record: any) => `${record.order_type} – ${moment(record.created_at).format("DD.MM.YY HH:mm")}`}
                                   fullWidth/>
            </ReferenceInput>
        </Filter>
    );
};

const statuses = {
    received: "received",
    confirmed: "confirmed",
    agentConfirmation: "agent-confirmation",
    docsFromAgent: "docs-from-agent",
    docsToClient: "docs-to-agent",
    docsFromClient: "docs-from-client",
    invoice: "invoice",
    invoiceHistory: "invoice-history",
}

const bulkActionButtons = (tabValue: string) => {
    return (
        <>
            { tabValue === statuses.received && <BulkDeleteButton mutationMode="pessimistic" /> }
            { tabValue === statuses.agentConfirmation && <MarkConfirmationFromAgentReceived /> }
            { tabValue === statuses.docsFromAgent && <MarkDocsFromAgentReceived />}
            { tabValue === statuses.docsFromClient && <MarkDocsFromClientReceived />}
            { tabValue === statuses.invoice && <MarkInvoiceSent />}
        </>
    );
}

const receivedFilter = {
    "order#confirmed_at@_is_null": true,
    // "order#confirmed_at@_is_null": false,
};

const confirmedFilter = {
    "pct_order#order#confirmed_at@_is_null": false,
};

const invoiceFilter = {
    "pct_order#order#confirmed_at@_is_null": false,
    "invoiced_at@_is_null": true,
};

const invoiceHistoryFilter = {
    "invoiced_at@_is_null": false,
};

const agentConfirmationFilter = {
    ...confirmedFilter,
    "instructions_to_agent_sent_at@_is_null": false,
    // "instructions_to_agent_sent_at@_is_null": true,
};
const docsFromAgentFilter = {
    ...agentConfirmationFilter,
    "agent_confirmation_received_at@_is_null": false,
    // "agent_confirmation_received_at@_is_null": true,
};
const docsToClientFilter = {
    ...docsFromAgentFilter,
    "client_documents_sent_at@_is_null": false,
    // "client_documents_sent_at@_is_null": true,
};

const docsFromClientFilter = {
    ...docsToClientFilter,
    "agent_documents_received_at@_is_null": false,
    // "agent_documents_received_at@_is_null": true,
};
const filters: { [key: string]: any } = {
    "received": receivedFilter,
    "confirmed": confirmedFilter,
    [statuses.agentConfirmation]: agentConfirmationFilter,
    [statuses.docsFromAgent]: docsFromAgentFilter,
    [statuses.docsToClient]: docsFromClientFilter,
    [statuses.docsFromClient]: docsFromClientFilter,
    "invoice": invoiceFilter,
    [statuses.invoiceHistory]: invoiceHistoryFilter,
}

const statusesArray = Object.values(statuses);
export const PCTOrdersList = (props: any) => {
    const [value, setValue] = React.useState<string>("received");
    const handleChange = (event: React.SyntheticEvent, newValue: string) => {
        setValue(newValue);
    }

    const filter =  filters[value] || {};
    const handledByOrder = [statuses.received];
    const handledByOrderLines = statusesArray.filter((status) => !handledByOrder.includes(status));

    return (
        <Page title={"PCT orders"}>
            <Tabs
                value={value}
                onChange={handleChange}
            >
                <Tab
                    value={statuses.received}
                    label={<Box>Received <Count filter={receivedFilter} resource={"pct_orders"}></Count></Box>}
                />
                <Tab value={statuses.confirmed} label={<Box>Send Filing Instructions <Count filter={filters[statuses.confirmed]} resource={"pct_order_lines"}></Count></Box>} />
                <Tab value={statuses.agentConfirmation} label={<Box>Awaiting Agent Confirmation <Count filter={filters[statuses.agentConfirmation]} resource={"pct_order_lines"}></Count></Box>} />
                <Tab value={statuses.docsFromAgent} label={<Box>Awaiting Docs Agent <Count filter={filters[statuses.docsFromAgent]} resource={"pct_order_lines"}></Count></Box>} />
                <Tab value={statuses.docsToClient} label={<Box>Send Docs to Client <Count filter={filters[statuses.docsToClient]} resource={"pct_order_lines"}></Count></Box>} />
                <Tab value={statuses.docsFromClient} label={<Box>Awaiting Docs Client <Count filter={filters[statuses.docsFromClient]} resource={"pct_order_lines"}></Count></Box>} />
                <Tab value={statuses.invoice} label={<Box>To be invoiced <Count filter={filters[statuses.invoice]} resource={"pct_order_lines"}></Count></Box>} />
                <Tab value={statuses.invoiceHistory} label="Invoice history" />
            </Tabs>
            {
                handledByOrder.includes(value) &&
                <List resource={"pct_orders"} {...props} sort={{ field: "created_at", order: "ASC" }} filter={filter}
                      empty={<HappyEmpty/>}
                >
                    <Datagrid
                        bulkActionButtons={bulkActionButtons(value)}
                        // expand={<PatentsExpanded />}
                    >
                        <TextField label="Client" source={"order.tenant.name"} ></TextField>
                        <FunctionField label={"Order #"} render={(record: any) => record?.order?.id?.split("-")[0]?.toUpperCase()} sortBy={"id"} />
                        <DateField source={"created_at"} showTime />
                        <DateField source={"updated_at"} showTime />
                        <TextField source={"order.order_type"} label={"Order Type"}/>

                        <TextField source={"case.case_ref"} label={"Case ref."}/>
                        <TextField source={"case.application_number"} label={"App. #"} />


                        <TextField label="Submitted by" source={"order.user.name"} ></TextField>
                        <TextField label={"User ref"} source={"user_reference"} ></TextField>
                        <TextField label={"User comments"} source={"user_comments"} ></TextField>
                        <TextField label={"Price"} source={"order.portal_price"} ></TextField>
                        {/*<TextField label={"Currency"} source={"order.price_currency"} ></TextField>*/}
                        <FunctionField label={"Answers"} render={(record: any) => {
                            return (
                                <>
                                    {
                                        record.pct_order?.answers?.map((answer: any) => (
                                            <Stack mb={2}>
                                                <Box>{answer.q}</Box>
                                                <Box fontWeight={"600"}>{answer.a}</Box>
                                            </Stack>
                                        ))
                                    }
                                    {
                                        record.pct_order?.pct_order_lines?.filter((line: any) => line.answers?.length > 0).map((order_line: any) => (
                                            <>
                                                <Box fontWeight={"600"}><Flag countryCode={order_line.country_code}></Flag> {" " + countries[order_line.country_code]}</Box>
                                                {order_line.answers?.map((answer: any) => (
                                                    <Stack mb={2}>
                                                        <Box>{answer.q}</Box>
                                                        <Box fontWeight={"600"}>{answer.a}</Box>
                                                    </Stack>
                                                ))}
                                            </>
                                        ))
                                    }
                                </>
                            );
                        }}></FunctionField>
                        <WithRecord label={"Selected countries"} render={(record: any) => {
                            return (
                                <FunctionField label={"Selected countries"} render={(record: any) => {
                                    return record?.pct_order_lines?.map((line: any) => (
                                        <Stack mb={2}>
                                            <Stack direction={"row"} spacing={2}>
                        <span>
                            <Flag countryCode={euFlagCountries.includes(line.country_code) ? "eu" : line.country_code}></Flag>{"  " + countries[line.country_code]}
                        </span>
                                                {
                                                    line.price &&
                                                    <span>({line.price} {line.price_currency})</span>
                                                }
                                            </Stack>
                                            {line?.case ? <Box sx={{ textWrap: "nowrap" }}><Link to={`/cases/${line.case?.id}/show`} target={"_blank"}>{line?.case?.case_ref}</Link></Box> : null}
                                            <Box sx={{ textWrap: "nowrap" }}>Official: {line.official_fee}. Service: {line.service_fee}. Translation: {line.translation_fee || "N/A"}</Box>
                                        </Stack>
                                    ));
                                }}></FunctionField>
                            );
                        }} />

                        { value === "received" && <SendOrderConfirmationEmailButton />}
                        {/*{ value === "confirmed" && <SendAgentInstructionsEmailButton />}*/}
                    </Datagrid>
                </List>
            }

            {
                handledByOrderLines.includes(value) &&
                <List resource={"pct_order_lines"} sort={{ field: "created_at", order: "ASC" }} filter={filter} filters={<PCTOrderLineFilters></PCTOrderLineFilters>}
                      empty={value !== statuses.invoiceHistory ? <HappyEmpty/> : false}
                >
                    <Datagrid
                        bulkActionButtons={bulkActionButtons(value)}
                    >
                        <TextField label="Client" source={"pct_order.order.tenant.name"} ></TextField>
                        <FunctionField label={"Order #"} render={(record: any) => record?.pct_order?.order?.id?.split("-")[0]?.toUpperCase()} sortBy={"id"} />
                        <DateField label={"Submitted"} source={"pct_order.order.created_at"} showTime />
                        <TextField label="Submitted by" source={"pct_order.order.user.name"} ></TextField>

                        <FunctionField label={"Case ref."} render={(record: any) => {
                            const case_ref = record?.case?.case_ref;
                            const id = record?.case?.id;
                            const casesShowPage = `/cases/${id}/show`;
                            return record ? <Link to={casesShowPage} target={"_blank"}>{case_ref}</Link> : null;
                        }} />
                        <TextField label="App. #" source={"case.application_number"} />
                        <TextField label={"User ref"} source={"pct_order.user_reference"} ></TextField>
                        <TextField label={"User comments"} source={"pct_order.user_comments"} ></TextField>
                        <TextField source={"official_fee"} ></TextField>
                        <TextField source={"translation_fee"} ></TextField>
                        <TextField source={"service_fee"} ></TextField>
                        <TextField source={"price"} ></TextField>
                        <TextField source={"price_currency"} ></TextField>
                        <FlagField label={"Country"} source={"country_code"} sx={{ textWrap: "nowrap" }}></FlagField>
                        <ReferenceField source="agent_id" reference="names">
                            <TextField source="name" />
                        </ReferenceField>
                        {/*<TextField label={"Agent"} source={"agent.name"}></TextField>*/}
                        <FunctionField label={"Agent email"} render={(record: any) => {
                            const email = record?.agent?.email;
                            return email ? email : `${emojis.redCircle} email missing ${emojis.redCircle}`;
                        }} />
                        {/*<TextField label={"Agent email"} source={"agent.email"}></TextField>*/}
                        <ReferenceOneField label={"Action"} target="case_id" reference="case_actions" source={"case.id"} filter={{"action_rule#action_type@_nin": [ANNUITY, RENEWAL]}}>
                            <FunctionField render={(record: any) => {
                                return (
                                    <Box>
                                        <Box sx={{textWrap: "nowrap" }}>{record?.action_rule?.action_name}</Box>
                                        <Box sx={{textWrap: "nowrap" }}>{moment(record?.due_date).format(dateFormat)}</Box>
                                        <Box sx={{textWrap: "nowrap" }}>{moment(record?.due_date).from(moment())}</Box>
                                    </Box>
                                )
                            }} />

                        </ReferenceOneField>
                        <OrderLineStatus label={"Status"}></OrderLineStatus>
                        { value === "confirmed" && <SendAgentInstructionsEmailButton />}
                        {/*{ value === statuses.agentConfirmation && <MarkConfirmationFromAgentReceived />}*/}
                        {/*{ value === statuses.docsFromAgent && <MarkDocsFromAgentReceived />}*/}
                        {/*{ value === statuses.docsFromClient && <MarkDocsFromClientReceived />}*/}
                    </Datagrid>
                </List>
            }
        </Page>
    );
};


