import {Box} from '@mui/system';
import React, { useCallback} from 'react';
import {
    BulkDeleteButton,
    Button,
    Create,
    Datagrid,
    DateField,
    FileField,
    FileInput,
    FunctionField,
    List,
    ReferenceField,
    SimpleForm,
    TextField,
    Title,
    useDataProvider,
    useRecordContext,
} from 'react-admin';
import {useMutation} from 'react-query';
import {useUserId} from '../auth/utils';
import {useTenantId} from "../data/useTenantId";
import {DocketButton} from "./DocketButton";
import {DownloadDocumentButton} from "../cases/crud/CaseShow";

export const Docketing = (props: any) => {
    const dataProvider = useDataProvider();
    const tenantId = useTenantId();
    const userId = useUserId();
    const {mutate} = useMutation(
        (data: any) => dataProvider.create("docketing_drafts", {
            data: data
        })
    );


    const save = useCallback(
        async (values: any) => {
            const {files, ...rest} = values;
            console.log(files, rest);

            const response = await fetch(`${process.env.REACT_APP_FUNCTIONS_URL}/getUploadUrl`)
                .then(res => res.json());

            console.log(response)
            const file = files.rawFile;
            const buffer = await file.arrayBuffer();
            let blobData = new Blob([buffer], {type: 'application/pdf'})
            console.log('Uploading to: ', response.uploadURL)
            const result = await fetch(response.uploadURL, {
                method: 'PUT',
                body: blobData
            })
            console.log('Result: ', result)

            const bucket_file_name = response.Key;

            const fileParams = {
                bucket_file_name,
                original_file_name: files.title
            }

            try {
                await mutate({
                    ...fileParams,
                    tenant_id: tenantId,
                    created_by: userId,
                });
            } catch (e) {
                const error = e as {
                    body: { errors: [] | {} }
                };
                if (error.body.errors) {
                    return error.body.errors;
                }
            }
        },
        [mutate],
    );


    return (
        <Box>
            <Title title={"Docketing"}/>
            <Create title="Create a document" {...props} resource={"docketing_drafts"} sx={{maxWidth: "1000px", mx: "auto"}}>
                <SimpleForm onSubmit={save}>
                    <FileInput source="files" label="Documents" accept="application/pdf" isRequired
                               sx={{ '& .RaFileInput-dropZone': { backgroundColor: "#eee" }}}
                    >
                        <FileField source="src" title="title"/>
                    </FileInput>
                </SimpleForm>
            </Create>
            <List
                resource={"docketing_drafts"}
                exporter={false}
                sort={{field: "created_at", order: "DESC"}}
                filter={{ completed_at: {
                        format: "hasura-raw-query",
                        value: {_is_null: true},
                    }
            }}
            >
                <Datagrid bulkActionButtons={<BulkDeleteButton mutationMode={"pessimistic"}/>}>
                    <TextField source={"original_file_name"} label={"File name"}></TextField>
                    <DateField source={"created_at"} showTime></DateField>
                    <ReferenceField source="created_by" reference="users">
                        <TextField source="name"/>
                    </ReferenceField>
                    {/*<DateField source={"completed_at"} showTime></DateField>*/}
                    {/*<TextField source={"completed_by"} ></TextField>*/}
                    <FunctionField
                        label={"Text"}
                        render={(record: any) => <span title={record?.document_text}>{record?.document_text?.length > 1000 ? record?.document_text?.substr(0, 1000) + "..." : record?.document_text}</span> }
                    ></FunctionField>
                    <TextField source={"ai_response"} ></TextField>
                    <FunctionField source={"ai_response"} render={(record: any) => {
                        const actions = JSON.parse(record["ai_response"])?.actions;
                        if (actions && actions.length) {
                            return actions.map((action: any) => {
                                const attributes = action.attributes || {};
                                return <div>{action.action_code}: {(Object.keys(attributes)).map((attr: string) => ` ${attr}: ${attributes[attr]}`)}</div>;
                            })
                        }

                        return record["ai_response"] ? <pre>{JSON.stringify(JSON.parse(record["ai_response"]), null, 2)}</pre> : null;
                    }}></FunctionField>
                    <DocketButton />
                    <ProcessButton />
                    <DownloadDocumentButton />
                </Datagrid>
            </List>
        </Box>
    );
}

const ProcessButton = (props: any) => {
    const record = useRecordContext();
    const dataProvider = useDataProvider();

    const processPdf = (event: any) => {
        console.log(event);
        event?.preventDefault();

        dataProvider.processDocument(record.id).then((res: any) => {
            console.log(res);
            alert("Document successfully sent for background processing. \n\n This means the content of the pdf will be read (no OCR) and AI will extract action rules and corresponding attributes. \n\n The process takes around 30 seconds");
        });
    }
    return <Button label={"Process"} onClick={processPdf}></Button>;
}

