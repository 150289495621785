import * as React from 'react';
import {Link, useRecordContext } from 'react-admin';
import {applicationNumberUrl} from "./utils";

export const ApplicationNumberField = (props: any) => {
    const record = useRecordContext(props);
    const countryCode = record?.country_code?.toUpperCase() || "";
    const externalUrl = applicationNumberUrl(record?.application_number, countryCode);
    return record ? <Link to={externalUrl.url} target={"_blank"} rel="noreferrer" title={externalUrl.title}>{record.application_number}</Link> : null;
};

ApplicationNumberField.defaultProps = { label: 'Application Number' };
