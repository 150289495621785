import {useIsAdmin, useUserId} from "../../auth/utils";
import {
    AutocompleteArrayInput,
    Button, ChipField, Create, CreateBase, email, Form,
    FormDataConsumer,
    FunctionField,
    Link,
    ListBase,
    ReferenceArrayInput,
    ReferenceField,
    required,
    SaveButton, ShowBase, SimpleForm, SimpleShowLayout,
    SingleFieldList,
    TabbedForm, TextField, TextInput, Toolbar, useCreate, useDataProvider, useListContext, useNotify, useRecordContext
} from "react-admin"
import {Dialog, DialogTitle, DialogContent, DialogActions, Tab, Divider, Chip, Grid} from '@mui/material';
import {Fragment, Suspense, useState} from "react";
import {LazySpinner, Spinner, SpinnerInline} from "../../utils/Spinner";
import React from "react";
import {Box, Stack} from "@mui/system";
import Mustache from 'mustache';
import SendIcon from "@mui/icons-material/Send";
import {useCompleteSubAction} from "./CompleteSubActionButton";
import moment from "moment";
import {useTenantId} from "../../data/useTenantId";
import {countries} from "../../utils/countries";
import { useFormContext } from "react-hook-form";
import OpenInNewIcon from '@mui/icons-material/OpenInNew';

const RichTextInput = React.lazy(() =>
    import('ra-input-rich-text').then(module => ({
        default: module.RichTextInput,
    }))
);

const LocalAgentField = (props: any) => {
    const { record } = props;
    const {setValue } = useFormContext()
    if (!record) return null;
    return <Chip label={`Local Agent : ${record.name} | ${record.email}`} onClick={() => setValue("recipient_email", record.email)}/>
}

const UserEmailField = (props: any) => {
    const { record } = props;
    const {setValue } = useFormContext()
    if (!record) return null;
    return <Chip label={`User: ${record.name} | ${record.email}`} onClick={() => setValue("recipient_email", record.email)}/>
}

const PrimaryAgentField = (props: any) => {
    const { record } = props;
    const {setValue } = useFormContext()
    if (!record) return null;
    return <Chip label={`Primary Agent : ${record.name} | ${record.email}`} onClick={() => setValue("recipient_email", record.email)}/>
}

export const PrepareEmailButton = (props: { caseId: string, emailTemplateId?: string, dueDate?: string }) => {
    const dataProvider = useDataProvider();
    const record = useRecordContext();
    const userId = useUserId();
    const isAdmin = useIsAdmin();
    const tenantId = useTenantId();
    const [open, setOpen] = useState(false);
    const [emailTemplate, setEmailTemplate] = useState<any>(null);
    const [caseData, setCaseData] = useState<any>(null);
    const [tabValue, setTabValue] = React.useState('1');
    const [create, {isLoading, error}] = useCreate('emails');
    const {data, isLoading: isCompleting, completeSubAction } = useCompleteSubAction();
    const notify = useNotify();
    const { refetch } = useListContext() || {};

    const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
        setTabValue(newValue);
    };
    const onClick = (e: any) => {
        e?.preventDefault();
        setOpen(true);
        console.log(record);

        dataProvider.getOne("email_templates", {
            id: props.emailTemplateId,
        }).then(res => {
            console.log(res);
            if (res.data) {
                setEmailTemplate(res.data);
            }
        });

        dataProvider.getOne("cases", {
            id: props.caseId,
        }).then(res => {
            console.log(res);
            if (res.data) {
                setCaseData({...res.data, country: countries[res.data?.country_code], due_date: props.dueDate ? moment(props.dueDate).format("YYYY-MM-DD") : ""});
            }
        }).catch(console.log);
    }
    const close = (e: any) => {
        e?.preventDefault && e?.preventDefault();

        setOpen(false)
    };

    const transformEmail = (data: any) => {
        return {
            ...data,
            subject: Mustache.render(data?.subject || "", caseData || {}),
            body: Mustache.render(data?.body || "", caseData || {}),
        }
    };

    const markCompleted = (emailData: any) => {
        completeSubAction(record.case_action_id, record.id, moment().format(), "", userId, emailData?.id).then(() => {
            notify(`Email sent and task marked completed`, { type: 'success' });
            refetch && refetch();
        });
    }

    console.log(record);

    return (
        <>
            <Button
                label={"Prepare email"}
                onClick={onClick}
            >
                <></>
            </Button>
            <Dialog open={open} onClose={close} fullWidth maxWidth={"lg"}>
                <DialogTitle>
                    Prepare and send email
                </DialogTitle>
                {isAdmin && emailTemplate?.id ?
                    <Stack sx={{
                        position: 'absolute',
                        right: 16,
                        top: 16,
                        color: (theme) => theme.palette.grey[500],
                    }} alignItems={"center"} direction={"row"}>
                        <Link to={`/email_templates/${emailTemplate?.id}`} target={"_blank"}>Edit template <OpenInNewIcon fontSize={"small"} sx={{verticalAlign: "bottom"}}></OpenInNewIcon></Link>
                    </Stack> : null
                }


                <DialogContent>
                    <Suspense fallback={<LazySpinner delay={500}/>}>
                        {
                            (emailTemplate && caseData) ?
                                <CreateBase {...props} resource={"emails"} transform={transformEmail} mutationOptions={{ onSuccess: close }}>
                                    <SimpleForm
                                        toolbar={
                                            <Toolbar>
                                                <Stack direction={"row"} spacing={2} justifyContent={"flex-end"} width={"100%"}>
                                                    <SaveButton
                                                        label={"Send email"}
                                                        alwaysEnable
                                                        icon={<></>}
                                                        variant={"text"}
                                                        endIcon={<SendIcon />}
                                                    />
                                                    <SaveButton
                                                        label={"Send email and complete"}
                                                        mutationOptions={{
                                                            onSuccess: (emailData: any) => {
                                                                markCompleted(null);
                                                            }}
                                                        }
                                                        type="button"
                                                        alwaysEnable
                                                        icon={<></>}
                                                        endIcon={<SendIcon />}
                                                    />
                                                </Stack>
                                            </Toolbar>
                                        }
                                        // maxWidth={"md"}
                                        // component={Fragment}
                                        defaultValues={{
                                            subject: emailTemplate.subject,
                                            body: emailTemplate.body,
                                            type: "CASE_SUB_ACTION",
                                            sending_status: "INITIATED_BY_USER",
                                            case_sub_action_id: record.id,
                                            tenant_id: tenantId,
                                            created_by: userId,
                                        }}
                                        // onSubmit={onSubmit}
                                    >
                                        <Grid container spacing={8}>
                                            <Grid xs={6} item>

                                            <ShowBase resource={"users"} id={userId}>
                                                <SimpleShowLayout>
                                                    <FunctionField render={(record: any) => {
                                                        return <UserEmailField record={record}></UserEmailField>
                                                    }}></FunctionField>
                                                </SimpleShowLayout>
                                            </ShowBase>
                                            <ShowBase resource={"cases"} id={caseData?.id}>
                                                <SimpleShowLayout>
                                                    <ReferenceField source="local_agent_id" reference="names" label={false} link={false}>
                                                        <FunctionField render={(record: any) => {
                                                            return <LocalAgentField record={record}></LocalAgentField>
                                                        }}></FunctionField>
                                                    </ReferenceField>
                                                    <ReferenceField source="primary_agent_id" reference="names" label={false} link={false}>
                                                        <FunctionField render={(record: any) => {
                                                            return <PrimaryAgentField record={record}></PrimaryAgentField>
                                                        }}></FunctionField>
                                                    </ReferenceField>
                                                </SimpleShowLayout>
                                            </ShowBase>
                                            <TextInput source={"recipient_email"} fullWidth
                                                       validate={[required(), email()]}></TextInput>
                                            <TextInput source={"subject"} fullWidth validate={[required()]}></TextInput>
                                            <Stack direction={"row"}>
                                                {/*<TextField source={"subject"}></TextField>*/}
                                            </Stack>
                                            <RichTextInput source={"body"} fullWidth label={false}
                                                           validate={[required()]}></RichTextInput>

                                            </Grid>
                                            <Grid xs={6} item>
                                                <ReferenceArrayInput label="Attchments" reference="case_documents" source="attachment_case_document_ids" filter={{ "case_id@_eq": caseData?.id}}>
                                                    <AutocompleteArrayInput
                                                        label="Attachments from case"
                                                        filterToQuery={(searchText: string) => ({title: `${searchText}`})}
                                                        sx={{ minWidth: 200 }}
                                                        optionText={"title"}
                                                    />
                                                </ReferenceArrayInput>


                                                {/*<ListBase resource={"case_documents"} filter={{ case_id: props.caseId }} storeKey={"attachments_case_documents"} sort={{ field: "updated_at", order: "DESC" }} perPage={50}>*/}
                                                {/*    <SingleFieldList>*/}
                                                {/*        <ChipField source={"title"}></ChipField>*/}
                                                {/*    </SingleFieldList>*/}
                                                {/*</ListBase>*/}
                                            </Grid>
                                        </Grid>
                                    </SimpleForm>
                                </CreateBase>
                                :
                                <SpinnerInline/>

                        }
                    </Suspense>


                    {/*<TabContext value={tabValue}>*/}
                    {/*    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>*/}
                    {/*        <TabList onChange={handleTabChange} aria-label="Tabs">*/}
                    {/*            <Tab label="Email edit" value="1" />*/}
                    {/*            <Tab label="Preview" value="2" />*/}
                    {/*        </TabList>*/}
                    {/*    </Box>*/}
                    {/*    <TabPanel value="1">*/}
                    {/*        <TextInput source={"recipient_email"} fullWidth validate={[required(), email()]}></TextInput>*/}
                    {/*        <TextInput source={"subject"} fullWidth validate={[required()]}></TextInput>*/}
                    {/*        <span></span>*/}
                    {/*        <Stack direction={"row"}>*/}
                    {/*            /!*<TextField source={"subject"}></TextField>*!/*/}
                    {/*        </Stack>*/}
                    {/*        <RichTextInput source={"body"} fullWidth label={false} validate={[required()]}></RichTextInput>*/}
                    {/*    </TabPanel>*/}
                    {/*    <TabPanel value="2" sx={{ minHeight: "400px"}}>*/}
                    {/*        <EmailPreview></EmailPreview>*/}
                    {/*    </TabPanel>*/}
                    {/*</TabContext>*/}
                </DialogContent>
                {/*<DialogActions>*/}
                {/*    <Button onClick={close} label={"Cancel"}></Button>*/}
                {/*    <Button onClick={sendEmail} label={"Send email"}></Button>*/}
                {/*</DialogActions>*/}
            </Dialog>
        </>
    );
}

const EmailPreview = (props: { caseData: any }) => {
    return (
        <FormDataConsumer>
            {({formData, ...rest}) => {
                return (
                    <Stack spacing={4} divider={<Divider/>} sx={{minHeight: "400px"}}>
                        <div>
                            <div><strong>Email subject:</strong></div>
                            <div
                                dangerouslySetInnerHTML={{__html: Mustache.render(formData?.subject || "", props.caseData || {})}}></div>
                        </div>
                        <div>
                            <div><strong>Email body:</strong></div>
                            <div
                                dangerouslySetInnerHTML={{__html: Mustache.render(formData?.body || "", props.caseData || {})}}></div>
                        </div>
                    </Stack>
                );
            }}
        </FormDataConsumer>

    );
}
