import {ExportConfiguration} from "../import/importReportExporter";
import {dateFormat} from "../actions/Actions";
import * as XLSX from 'xlsx-js-style';
import moment from "moment";
import {access} from "../../utils/utils";

export const exportConfiguration: ExportConfiguration = [
    {field: "case_action.case.type", header: "Domain"},
    {field: "case_action.case.application_number", header: "Application #"},
    {field: "case_action.case.registration_number", header: "Registration #"},
    {field: "official_fee", header: "Expected fee"},
    {field: "case_action.case.case_ref", header: "Breeze Case Ref"},
    {field: "case_action.case.tenant_case_ref", header: "Client Case Ref"},
    {field: "case_action.case.country_code", header: "Country"},
    {field: "case_action.case.applicant", header: "Applicant"},
    {field: "case_action.case.filing_date", header: "Filing Date"},
    {field: "case_action.case.international_filing_date", header: "Int. Filing Date"},
    {field: "case_action.case.registration_date", header: "Registration date"},
    {field: "type", header: "Type",},

    {field: "case_action.action_rule.action_name", header: "Annuity/Renewal"},
    {field: "instruction", header: "Instruction"},
    {field: "user.name", header: "Instructed by"},
    {field: "created_at", header: "Instructed at"},
    {field: "case_action.instructions_confirmed_by_payment_agent_at", header: "Confirmed by Breeze"},
];


const toArrayOfArrays = (data: any[], config: ExportConfiguration) => {
    const headerLines = config.map((config) => {
        return config.header || config.field;
    });
    const contentLines = data.map((item) => {
        return config.map((config) => {
            return access(config.field, item);
        });
    })
    return [
        headerLines,
        ...contentLines,
    ];
}

const caseRefToTypeNumber = (case_ref: string) => {
    if (case_ref.startsWith("D")) {
        return "3";
    } else if (case_ref.startsWith("TM")) {
        return "2";
    } else {
        // Patents
        return "1";
    }
}

export const norwegianInstructionsExportToExcel = (data: any[]) => {
    console.log("EXPORT: ", data);
    const transformedData = data.map((item) => {
        const caseRef = item?.case_action?.case?.case_ref;
        return {
            ...item,
            official_fee: item?.official_fee?.toString(),
            case_action: {
                ...item?.case_action,
                case: {
                    ...item?.case_action?.case,
                    country_code: item?.case_action?.case?.country_code?.toUpperCase(),
                    application_number: item?.case_action?.case?.registration_number?.includes("NO/EP") ? "" : item?.case_action?.case?.application_number,
                    type: caseRefToTypeNumber(caseRef)
                }
            },
            type: caseRef?.startsWith("D") ? "Design" :
                caseRef?.startsWith("TM") ? "Trademark" : "Patent",
        }
    });
    const emptyTop8 = Array(8).fill([]);
    const arrayOfArrays = toArrayOfArrays(transformedData, exportConfiguration);
    const ws = XLSX.utils.aoa_to_sheet([...emptyTop8, ...arrayOfArrays]);

    ws["!cols"] = (arrayOfArrays?.[0] || []).map((header: any) => {
            const found = exportConfiguration.find((config) => config.header === header || config.field === header);
            return ({
                wch: found?.width || transformedData.reduce((w, r) => Math.max(w, r?.[header]?.length || 0), header.length)
            });
        }
    );

    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Breeze NO instructions");
    XLSX.writeFile(wb, `breeze_NO_instructions_${moment().format(dateFormat)}.xlsx`);
}

