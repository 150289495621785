import * as React from "react";
import { List, Datagrid, Edit, Create, SimpleForm, TextField, EditButton, TextInput, SelectInput, ImageField, UrlField, FunctionField, FormDataConsumer, AutocompleteArrayInput, Filter, BooleanInput, SimpleFormIterator, ReferenceManyField, SingleFieldList, ReferenceInput, AutocompleteInput, required, NumberInput, Count, DateField, ReferenceField, BulkDeleteButton, ExportButton, CreateButton, TopToolbar, useResourceContext, useRecordContext} from 'react-admin';
import PersonIcon from '@mui/icons-material/Person';
import {useTenantId} from "../data/useTenantId";
import {countries, countriesOptions} from "../utils/countries";
import {useIsAdmin} from "../auth/utils";
import {ReferenceManyInput } from "@react-admin/ra-relationships";
import { Box } from "@mui/system";
import {useTenantContext} from "../react-admin-overrides/AppLayout";
import {Page} from "../utils/Page";
import {Tab, Tabs } from "@mui/material";
import {MarkInvoiceSent} from "../cases/instructions/MarkInvoiceSent";
import {PDFViewer} from "../utils/PDFViewer";
import { CreateInDialogButton } from "@react-admin/ra-form-layout";
import {CountryInput} from "../utils/CountryInput";
import {caseStatusList} from "../cases/crud/utils";
import {ProductInput} from "../cases/crud/CaseInputFields";
import {downloadExcelDesigns} from "../cases/export/designs-export";

const Filters = (props: any) => {
    const isAdmin = useIsAdmin();
    return (
        <Filter {...props}>
            <SelectInput source={"type"} optionText={"name"} choices={typeOptions} alwaysOn />
            <TextInput label={"Search"} source={"type,title,case#case_ref,case#tenant_case_ref,case#applicant"} alwaysOn />
        </Filter>
    );
};

const tabState: { [key: string]: any} = {
    nonInvoiced: {
        value: "nonInvoiced",
        title: "To be invoiced",
        filter: {
            "invoiced_at@_is_null": true,
        }
    },
    invoiced: {
        value: "invoiced",
        title: "Invoiced",
        filter: {
            "invoiced_at@_is_null": false,
        }
    }
}
const combineFilters = (filterA: any, filterB: any) => ({
    ...(filterA || {}),
    ...(filterB || {})
});
export const ChargesList = (props: any) => {
    const [value, setValue] = React.useState<string>(tabState.nonInvoiced.value);
    const handleChange = (event: React.SyntheticEvent, newValue: string) => {
        setValue(newValue);
    }

    const resource = "charges";
    return (
        <Page>
            <Tabs
                value={value}
                onChange={handleChange}
            >
                <Tab
                    value={tabState.nonInvoiced.value}
                    label={<Box>{tabState.nonInvoiced.title} <Count filter={combineFilters(props?.filter, tabState.nonInvoiced.filter)} resource={resource}></Count></Box>}
                />
                <Tab value={tabState.invoiced.value} label={<Box>{tabState.invoiced.title} <Count filter={combineFilters(props?.filter, tabState.invoiced.filter)} resource={resource}></Count></Box>} />
            </Tabs>
            <List resource={"charges"}
                  {...props}
                  sort={{ field: "created_at", order: "ASC" }}
                  filters={<Filters/>}
                  filter={combineFilters(props?.filter, tabState[value]?.filter)}
                  empty={false}
                  actions={<Actions caseId={props?.caseId}></Actions>}
                  bulkActionButtons={
                    <>
                        {
                            value === tabState.nonInvoiced.value && <MarkInvoiceSent></MarkInvoiceSent>
                        }
                        <BulkDeleteButton mutationMode="pessimistic"></BulkDeleteButton>
                    </>
                  }
            >
                <Datagrid>
                    <TextField label={"Case ref."} source={"case.case_ref"} />
                    {/*<TextField label={"Client ref."} source={"case.tenant_case_ref"} />*/}
                    <TextField source={"type"} />
                    <TextField source={"title"} />
                    <TextField source={"sum"} />
                    <TextField source={"currency"} />
                    {
                        value === tabState.invoiced.value &&
                        <DateField label="Invoiced at" source={"invoiced_at"} showTime />
                    }
                    {
                        value === tabState.invoiced.value &&
                        <ReferenceField reference="users" source={"invoiced_by"} label={"Invoiced by"} link={false}>
                            <TextField source={"name"}/>
                        </ReferenceField>
                    }
                    <EditButton />
                </Datagrid>
            </List>
        </Page>
    );
};

const typeOptions = ["Disbursement", "Official fee", "Hourly work", "Other"].map((type) => ({ id: type, name: type}))
const currencyOptions = ["NOK", "EUR", "USD"].map((currency) => ({id: currency, name: currency}));
export const ChargesEdit = (props: any) => {
    const {tenant} = useTenantContext() || {};
    return (
        <Edit {...props} mutationMode="pessimistic" redirect={"list"}>
            <SimpleForm>
                <ReferenceInput source="case_id" reference="patents">
                    <AutocompleteInput
                        validate={required()}
                        fullWidth
                        optionText={(caseRecord) => `${caseRecord.case_ref || ""} – ${caseRecord.application_number || ""} – ${caseRecord.catchword || ""}`}
                        filterToQuery={(searchText: string) => ({
                            "case_ref,tenant_case_ref,application_number": `${searchText}`,
                        })}
                    />
                </ReferenceInput>
                <SelectInput source={"type"} optionText={"name"} choices={typeOptions} alwaysOn />
                <TextInput source={"title"} />
                <NumberInput source={"sum"} />
                <SelectInput source={"currency"} choices={currencyOptions} defaultValue={tenant?.currency_code || undefined} required />
            </SimpleForm>
        </Edit>
    );
};

export const ChargesCreate = (props: any) => {
    const {tenant} = useTenantContext() || {};
    return (
        <Create title="Create a name" {...props} mutationMode="pessimistic" redirect={"list"}>
            <SimpleForm>
                <ReferenceInput source="case_id" reference="patents">
                    <AutocompleteInput
                        validate={required()}
                        fullWidth
                        optionText={(caseRecord) => `${caseRecord.case_ref || ""} – ${caseRecord.application_number || ""} – ${caseRecord.catchword || ""}`}
                        filterToQuery={(searchText: string) => ({
                            "case_ref,tenant_case_ref,application_number": `${searchText}`,
                        })}
                    />
                </ReferenceInput>
                <SelectInput source={"type"} optionText={"name"} choices={typeOptions} alwaysOn />
                <TextInput source={"title"} />
                <NumberInput source={"sum"} />
                <SelectInput source={"currency"} choices={currencyOptions} defaultValue={tenant?.currency_code || undefined} required />
            </SimpleForm>
        </Create>
    );
};

export const ChargesCreateInDialog = (props: any) => {
    const {tenant} = useTenantContext() || {};
    const record = props?.caseId ? { case_id: props.caseId } : {};
    return (
        <CreateInDialogButton
            resource="charges"
            title={props?.title || "Create charge"}
            label={props?.label || "Create charge"}
            maxWidth={"md"}
            fullWidth
            record={record}
        >
            <SimpleForm>
                <ReferenceInput source="case_id" reference="patents">
                    <AutocompleteInput
                        validate={required()}
                        fullWidth
                        optionText={(caseRecord) => `${caseRecord.case_ref || ""} – ${caseRecord.application_number || ""} – ${caseRecord.catchword || ""}`}
                        filterToQuery={(searchText: string) => ({
                            "case_ref,tenant_case_ref,application_number": `${searchText}`,
                        })}
                    />
                </ReferenceInput>
                <SelectInput source={"type"} optionText={"name"} choices={typeOptions} alwaysOn />
                <TextInput source={"title"} />
                <NumberInput source={"sum"} />
                <SelectInput source={"currency"} choices={currencyOptions} defaultValue={tenant?.currency_code || undefined} required />
            </SimpleForm>
        </CreateInDialogButton>
    );
}

const Actions = (props: any) => {
    return (
        <TopToolbar>
            {/*<CreateButton />*/}
            <ChargesCreateInDialog caseId={props?.caseId} label={"Create"}></ChargesCreateInDialog>
            <ExportButton />
        </TopToolbar>
    );
};

