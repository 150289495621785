import { Box } from "@mui/system";
import moment from "moment";
import { useRecordContext } from "react-admin";
import {dateFormat} from "../cases/actions/Actions";


export const OrderLineStatus = (props: any) => {
    const record = useRecordContext();
    if (!record) return null;


    const pct_or_ep_order = record?.ep_order || record?.pct_order;
    return (
        <Box>
            {pct_or_ep_order?.order?.confirmed_at && <Box sx={{textWrap: "nowrap" }}>Confirmed: {moment(pct_or_ep_order?.order?.confirmed_at).format(dateFormat)}</Box>}
            {record?.instructions_to_agent_sent_at && <Box sx={{textWrap: "nowrap" }}>Sent to Agent: {moment(record?.instructions_to_agent_sent_at).format(dateFormat)}</Box>}
            {record?.agent_confirmation_received_at && <Box sx={{textWrap: "nowrap" }}>Agent confirmed: {moment(record?.agent_confirmation_received_at).format(dateFormat)}</Box>}
        </Box>
    );
}

