import { Alert, AlertTitle } from "@mui/material";
import { BulkUpdateFormButton } from "@react-admin/ra-form-layout";
import moment from "moment";
import {DateTimeInput, TextInput, SimpleForm, SaveButton, Toolbar, useRecordContext, useListContext } from "react-admin";
import {useUserId} from "../../auth/utils";


export const HANDLER_BREEZE = "Breeze";
export const SetBreezeAsHandlerButton = (props: any) => {
    return (
        <BulkUpdateFormButton label={"Set Breeze as handler"} icon={<></>}>
            <SimpleForm
                toolbar={
                    <Toolbar>
                        <SaveButton label={"Save"} alwaysEnable />
                    </Toolbar>
                }
                sx={{maxWidth: 600}} defaultValues={{
                handler: HANDLER_BREEZE,
            }}>
                <Alert variant="outlined" severity="info">
                    <AlertTitle>Set Breeze as handler</AlertTitle>
                    The instruction will be moved to the Breeze menu. Nothing else changes.
                </Alert>
            </SimpleForm>
        </BulkUpdateFormButton>
    );
}
