import * as React from "react";
import {
    AutocompleteArrayInput,
    AutocompleteInput,
    BooleanInput,
    BulkDeleteButton,
    BulkExportButton,
    Button,
    ChipField,
    CreateButton,
    Datagrid,
    DatagridConfigurable,
    DateField,
    EditButton,
    ExportButton,
    Filter,
    FilterButton,
    FunctionField,
    Link,
    List,
    Pagination,
    ReferenceArrayInput,
    ReferenceField,
    ReferenceInput,
    ReferenceManyField,
    ReferenceOneField,
    SaveButton,
    SelectColumnsButton,
    SelectInput,
    ShowButton,
    SimpleForm,
    TextField,
    TextInput,
    Toolbar,
    TopToolbar,
    useRecordContext,
    useUnselectAll,
    WrapperField
} from 'react-admin';
import {BulkUpdateFormButton, InputSelectorForm} from '@react-admin/ra-form-layout';
import WorkIcon from '@mui/icons-material/Work';
import PublishIcon from '@mui/icons-material/Publish';
import {AnnuityCaseActionsList, CaseActionsList} from "./crud/CaseActionsList";
import {countries} from "../utils/countries";
import {CopyCaseButton} from "./CopyCaseButton";
import {useIsAdmin} from "../auth/utils";
import {ApplicantNameInput} from "./crud/ApplicantNameInput";
import {CreateActionsButton} from "./actions/CreateActionsButton";
import {Page} from "../utils/Page";
import {TenantField} from "../utils/tenant";
import { CountryInput } from "../utils/CountryInput";
import {capitalizeFirstLetter, dateFormat} from "./actions/Actions";
import moment from "moment";
import { CaseRefField } from "./crud/CaseRefField";
import {DepartmentInput, ProductInput} from "./crud/CaseInputFields";
import {ANNUITY, RENEWAL} from "./actions/utils";
import {ApplicationNumberField} from "./crud/AppplicationNumberField";
import {CitationsField} from "./crud/CitationsField";
import {Box, Chip, Stack, Tooltip } from "@mui/material";
import {FlagField} from "../utils/FlagField";
import {TenantContext} from "../react-admin-overrides/AppLayout";
import {RecreateActionsButton, RecreateActionsForm} from "./crud/CaseShow";
import {SyncWithEPOButton} from "./crud/CaseSyncWithEPO";
import {downloadExcelPatents} from "./export/patents-export";
import { caseStatusList } from "./crud/utils";

export const CaseIcon = WorkIcon;

export const compactTable = {
    ['.MuiTableCell-root']: {
        px: "6px",
        py: "4px",
    }
};

export const alternateBackground = (record: any, index: number) => ({
    backgroundColor: index % 2 === 0 ? '#f1f1f1' : 'white',
});


export type Case = {
    application_number: string;
    country_code: string;
    application_type: string;
    case_type: string;
    created_at: string;
    updated_at: string;
}

export enum CASE_FIELDS {
    TENANT_ID = "tenant_id",
    CASE_REF = "case_ref",
    APPLICATION_NUMBER = "application_number",
    CATCHWORD = "catchword",
    INVENTION_TITLE = "invention_title",
    COUNTRY_CODE = "country_code",
    APPLICATION_TYPE = "application_type",
    CASE_TYPE = "case_type",
    STATUS = "status",
    APPLICANT = "applicant",
    CREATED_AT = "created_at",
    UPDATED_AT = "updated_at",
    FILING_DATE = "filing_date",
    INTERNATIONAL_FILING_DATE = "international_filing_date",
    NATIONAL_FILING_DATE = "national_filing_date",
    REGISTRATION_DATE = "registration_date",
    REGISTRATION_NUMBER = "registration_number",
    PUBLICATION_DATE = "publication_date",
    INTENTION_TO_GRANT_DATE = "intention_to_grant_date",
    PUBLICATION_NUMBER = "publication_number",
    FIRST_PRIORITY_FILING_DATE = "first_priority_filing_date",
    PRIORITY_APPLICATION_NUMBER = "priority_application_number",
    PRIORITY_COUNTRY_CODE = "priority_country_code",
    DESIGNATED_STATES = "designated_states",
    DEMAND_FOR_CHAPTER_2 = "demand_for_chapter_II",
    APPLICATION_TYPE_2 = "application_type_2",
    BASED_ON_PCT = "based_on_pct",
    PRODUCT = "product",
    DEPARTMENT = "department",
}

export const CASE_TYPE_PATENT = "P";
const patent = {
    id: CASE_TYPE_PATENT,
    name: "P – Patent",
    resource: "patents"
}
export const CASE_TYPE_TRADEMARK = "TM";
const trademark = {
    id: CASE_TYPE_TRADEMARK,
    name: "TM – Trademark",
    resource: "trademarks"
}
export const CASE_TYPE_DESIGN = "D";
const design = {
    id: CASE_TYPE_DESIGN,
    name: "D – Design",
    resource: "designs"
}

export const CASE_TYPE_OPPOSITION = "O";
const opposition = {
    id: CASE_TYPE_OPPOSITION,
    name: "O – Opposition"
}

export const CASE_TYPE_INFRINGEMENT = "I";
const infringement = {
    id: CASE_TYPE_INFRINGEMENT,
    name: "I – Infringement"
}

export const CASE_TYPE_INVENTION = "INV";
const invention = {
    id: CASE_TYPE_INVENTION,
    name: "INV – Invention"
}

export const CASE_TYPE_PATENT_SEARCH = "SP";
const patentSearch = {
    id: CASE_TYPE_PATENT_SEARCH,
    name: "SP – Patent Search"
}

export const CASE_TYPE_TRADEMARK_SEARCH = "STM";
const trademarkSearch = {
    id: CASE_TYPE_TRADEMARK_SEARCH,
    name: "STM – Trademark Search"
}

export const CASE_TYPE_AGREEMENT = "A";
const agreement = {
    id: CASE_TYPE_AGREEMENT,
    name: "A – Agreement"
}

export const CASE_TYPE_COURT_CASE = "C";
const courtCase = {
    id: CASE_TYPE_COURT_CASE,
    name: "C – Court Case"
}

export const CASE_TYPE_DOMAINS = "@";
const domains = {
    id: CASE_TYPE_DOMAINS,
    name: "@ – Domains"
}

export const CASE_TYPE_COPYRIGHT = "©";
const copyright = {
    id: CASE_TYPE_COPYRIGHT,
    name: "© – Copyright"
}

export const caseTypeList: Array<{id: string, name: string, resource?: string}> = [patent, trademark, design, opposition, infringement, invention, patentSearch, trademarkSearch, agreement, courtCase, domains, copyright];
export const caseTypeOptions = caseTypeList;

export const PCTBased = "PCT Based";
export const UtilityModel = "Utility Model";
export const ValidatedEPC = "Validated EPC";
export const PriorityFounding = "Priority founding";
export const Direct = "Direct";
const applicationTypeList = [ValidatedEPC, PCTBased, Direct, UtilityModel, PriorityFounding];
export const applicationTypeOptions = applicationTypeList.map((s) => ({id: s, name: s}));
export const applicationType2List = [...applicationTypeList, "Divisional", "Provisional", "Continuation"];
export const applicationType2Options = applicationType2List.map((s) => ({id: s, name: s}));

const CasesFilter = (props: any) => (
    <Filter {...props}>
        <CountryInput label={"Country"} source={"country_code"} />
        <TextInput source="case_ref"></TextInput>
        <TextInput source="tenant_case_ref" label={"Client ref"}></TextInput>
        <TextInput label="Smart search" alwaysOn source="case_ref,tenant_case_ref,application_number,catchword,registration_number,applicant"></TextInput>
        <TextInput source="application_number"></TextInput>
        <TextInput source="catchword"></TextInput>
        <TextInput source="registration_number"></TextInput>
        <TextInput source="applicant"></TextInput>
        <TextInput source="based_on_pct"></TextInput>
        <ProductInput source={"product"}></ProductInput>
        <DepartmentInput source={"department"}></DepartmentInput>
        <ReferenceInput reference="names" source="primary_agent_id" label="Primary Agent" filter={{ type: "Agent" }}>
            <AutocompleteInput filterToQuery={(searchText: string) => ({ "name,short_name": `${searchText}` })} sx={{ width: 200 }}/>
        </ReferenceInput>
        <ReferenceInput reference="names" source="local_agent_id" label="Local Agent" filter={{ type: "Agent" }}>
            <AutocompleteInput filterToQuery={(searchText: string) => ({ "name,short_name": `${searchText}` })} sx={{ width: 200 }}/>
        </ReferenceInput>
        {/*<ReferenceInput reference="tenants" source="tenant_id" label="Client">*/}
        {/*    <AutocompleteInput filterToQuery={(searchText: string) => ({name: `${searchText}`})} label={"Client"} />*/}
        {/*</ReferenceInput>*/}
        <AutocompleteArrayInput source={"status"} choices={caseStatusList.map((status: string) => ({ id: status, name: status}))}  />
        {/*<ReferenceArrayInput label="Clients" reference="tenants" source="tenant_id" >*/}
        {/*    <AutocompleteArrayInput label="Clients" filterToQuery={(searchText: string) => ({name: `${searchText}`})} sx={{ minWidth: 200 }}/>*/}
        {/*</ReferenceArrayInput>*/}
        <ReferenceArrayInput label="Multiple Case Refs" reference="cases" source="id" >
            <AutocompleteArrayInput label="Multiple Case Refs" filterToQuery={(searchText: string) => ({case_ref: `${searchText}`})} sx={{ minWidth: 200 }} optionText={"case_ref"}/>
        </ReferenceArrayInput>
        <ReferenceArrayInput label="Import session" reference="import_sessions" source="import_session_id" sort={{field: "created_at", order: "DESC" }}>
            <SelectInput label={"Import session"} optionText={(record: any) => `${moment(record.created_at).format("DD.MM.YY HH:mm")} – ${record.search_text}`}/>
        </ReferenceArrayInput>
    </Filter>
);

const CasesActions = () => {
    const isAdmin = useIsAdmin();
    const commonFilters = [
        <CountryInput label={"Country"} source={"country_code"} />,
        <TextInput source="case_ref"></TextInput>,
        <TextInput source="tenant_case_ref" label={"Client ref"}></TextInput>,
        <TextInput label="Smart search" alwaysOn source="case_ref,tenant_case_ref,application_number,catchword,registration_number,priority_application_number"></TextInput>,
        <TextInput source="application_number"></TextInput>,
        <TextInput source="registration_number"></TextInput>,
        <TextInput source="applicant"></TextInput>,
        <TextInput source="based_on_pct"></TextInput>,
        <ProductInput source={"product"}></ProductInput>,
        <DepartmentInput source={"department"}></DepartmentInput>,
        <AutocompleteArrayInput source={"status"} choices={[{id: "Pending", name: "Pending"}, {id: "Closed", name: "Closed"}, {id: "Granted", name: "Granted"}]}  />,
    ];

    const filters = isAdmin ? [
        ...commonFilters,
        <ReferenceInput reference="names" source="primary_agent_id" label="Primary Agent" filter={{ type: "Agent" }}>
            <AutocompleteInput filterToQuery={(searchText: string) => ({ "name,short_name": `${searchText}` })} />
        </ReferenceInput>,
        <ReferenceInput reference="names" source="local_agent_id" label="Local Agent" filter={{ type: "Agent" }}>
            <AutocompleteInput filterToQuery={(searchText: string) => ({ "name,short_name": `${searchText}` })} />
        </ReferenceInput>,
        // <ReferenceInput reference="tenants" source="tenant_id" label="Client">
        //     <AutocompleteInput filterToQuery={(searchText: string) => ({name: `${searchText}`})} label={"Client"} />
        // </ReferenceInput>,
        // <ReferenceArrayInput label="Clients" reference="tenants" source="tenant_id" >
        //     <AutocompleteArrayInput label="Clients" filterToQuery={(searchText: string) => ({name: `${searchText}`})} sx={{ minWidth: 200 }} />
        // </ReferenceArrayInput>,

        <ReferenceArrayInput label="Multiple Case Refs" reference="cases" source="id" >
            <AutocompleteArrayInput label="Multiple Case Refs" filterToQuery={(searchText: string) => ({case_ref: `${searchText}`})} sx={{ minWidth: 200 }} optionText={"case_ref"}/>
        </ReferenceArrayInput>,
        <ReferenceArrayInput label="Import session" reference="import_sessions" source="import_session_id" sort={{field: "created_at", order: "DESC" }}>
            <SelectInput label={"Import session"} optionText={(record: any) => `${moment(record.created_at).format("DD.MM.YY HH:mm")} – ${record.search_text}`}/>
        </ReferenceArrayInput>,
    ] : commonFilters;
    return (
        <TopToolbar>
            <FilterButton filters={filters}/>
            <CreateButton/>
            <SelectColumnsButton />
            <ExportButton exporter={downloadExcelPatents}/>
            {isAdmin &&
                <Button
                    component={Link}
                    to={`/import`}
                    label="Import"
                >
                    <PublishIcon/>
                </Button>
            }
        </TopToolbar>
    );
};

const CasesBulkUpdateButton = () => (
    <BulkUpdateFormButton>
            <InputSelectorForm
                inputs={[
                    {
                        label: 'Applicant',
                        element: <ApplicantNameInput/>,
                    },
                    {
                        label: 'Client',
                        element:
                            <ReferenceInput source="tenant_id" reference="tenants">
                                <AutocompleteInput filterToQuery={(searchText: string) => ({name: `${searchText}`})} label="Client"
                                                   fullWidth/>
                            </ReferenceInput>,
                    },
                    {
                        label: 'Primary Agent',
                        element:
                            <ReferenceInput source="primary_agent_id" reference="names" filter={{ type: "Agent" }}>
                                <AutocompleteInput filterToQuery={(searchText: string) => ({ "name,short_name": `${searchText}` })} />
                            </ReferenceInput>,
                    },
                    {
                        label: 'Local Agent',
                        element:
                            <ReferenceInput source="local_agent_id" reference="names" filter={{ type: "Agent" }}>
                                <AutocompleteInput filterToQuery={(searchText: string) => ({ "name,short_name": `${searchText}` })} />
                            </ReferenceInput>,
                    },
                    {
                        label: 'Product',
                        element: <ProductInput source={"product"}></ProductInput>,
                    },
                    {
                        label: 'Department',
                        element: <DepartmentInput source={"department"}></DepartmentInput>,
                    },
                ]}
            />






    </BulkUpdateFormButton>
);

export const CasesBulkRecreateActionsButton = () => {
    const record = useRecordContext();

    return (
        <BulkUpdateFormButton
            label={"Recreate actions"}
            icon={<></>}
        >
            <RecreateActionsForm />
        </BulkUpdateFormButton>
    );
};

const bulkActionButtons: React.ReactElement = <>
    <CasesBulkUpdateButton/>
    <CasesBulkRecreateActionsButton/>
    <BulkExportButton></BulkExportButton>
    <BulkDeleteButton mutationMode="pessimistic"/>
</>;
export const alternateBackgroundColor = (record: any, index: number) => ({
    backgroundColor: index % 2 === 0 ? '#f1f1f1' : 'white',
});

export const caseActionsFilter = {
    "action_rule#action_type@_nin": [ANNUITY, RENEWAL],
    "completed_at@_is_null": true
};
export const CaseList = (props: any) => {
    const isAdmin = useIsAdmin();
    const unselectAll = useUnselectAll("cases");
    React.useEffect(() => {
        unselectAll()
    }, []);

    const config = {
        bulkActions: isAdmin ? bulkActionButtons : false as false,
        rowClick: isAdmin ? "expand" : undefined,
        expand: isAdmin ?
            <div>
                <h3>Actions</h3>
                <ReferenceManyField label="Actions" reference="case_actions" target="case_id"
                                    sort={{field: "due_date", order: "ASC"}}
                                    filter={caseActionsFilter}
                >
                <CaseActionsList />
                </ReferenceManyField>
                <h3>Annuities</h3>
                <ReferenceManyField label="Actions" reference="case_actions" target="case_id"
                                    sort={{field: "due_date", order: "ASC"}} filter={{ "action_rule#action_type": ANNUITY }}>
                    <AnnuityCaseActionsList />
                </ReferenceManyField>
            </div> : undefined
    }

    return (
        <Page title={"Patents"} body={<>
            The table below shows the patent cases that have been imported to the portal. You can use the filters (available at the right, above the table) to narrow your list of cases.
            Further details on the case can be found via the "view details" button, and annuities connected to the case can be found under "annuities" (the right column).
            <br/><br/>
            The annuities are also shown on the "Annuities" menu, and from this annuity menu you can also send payment instructions.
        </>}>
            <List {...props}
                  sort={{field: "case_ref", order: "ASC"}}
                  filter={{ "case_type@_eq": "P" }}
                  filters={<CasesFilter/>}
                  actions={<CasesActions/>}
                  perPage={100}
                  pagination={<Pagination rowsPerPageOptions={[10, 25, 50, 100]} />}
            >
                <Pagination rowsPerPageOptions={[10, 25, 50, 100]} />
                <PatentsDatagrid config={config}></PatentsDatagrid>
            </List>
        </Page>
    );
};

const omitFields = [
    "id", "primary_agent_id", "local_agent_id", "created_at", "expiration_date", "calc_maintenance_actions_and_fees", CASE_FIELDS.PUBLICATION_DATE, CASE_FIELDS.PUBLICATION_NUMBER,
    "citations",
];

export const PatentsDatagrid = (props: any) => {
    const isAdmin = useIsAdmin();
    return (
        <DatagridConfigurable omit={omitFields} bulkActionButtons={props.config.bulkActions} rowClick={props.config.rowClick} rowSx={alternateBackgroundColor} sx={compactTable}
                              expand={props.config.expand}>
            {/*<TextField source="id" />*/}
            {isAdmin && <TenantField label={"Client"}/>}

            <CaseRefField source={CASE_FIELDS.CASE_REF}></CaseRefField>
            <TextField source={"tenant_case_ref"} label={"Client ref"}/>
            {/*<TextField source={CASE_FIELDS.CASE_TYPE}/>*/}
            <TextField source={CASE_FIELDS.APPLICANT}/>
            <TextField source={"id"}/>
            <DateField source={"created_at"} showTime/>
            <FlagField label={"Country"} source={"country_code"} sx={{ textWrap: "nowrap" }}></FlagField>
            <ApplicationNumberField source={CASE_FIELDS.APPLICATION_NUMBER}/>
            <TextField source={CASE_FIELDS.CATCHWORD}/>
            <FunctionField
                label={"Title"}
                source={CASE_FIELDS.INVENTION_TITLE}
                render={(record: any) => <span title={record?.invention_title}>{record?.invention_title?.length > 50 ? record?.invention_title?.substr(0, 50) + "..." : record?.invention_title}</span> }
            ></FunctionField>
            <TextField source={CASE_FIELDS.APPLICATION_TYPE}/>
            <FunctionField
                label="Priority claim"
                render={(record: any) => record[CASE_FIELDS.PRIORITY_APPLICATION_NUMBER] ? "With priority" : "Without priority"}
            />
            <TextField source={CASE_FIELDS.BASED_ON_PCT}/>
            <DateField source={CASE_FIELDS.FILING_DATE}/>
            <DateField source={CASE_FIELDS.INTERNATIONAL_FILING_DATE}/>
            <DateField source={CASE_FIELDS.NATIONAL_FILING_DATE}/>
            <DateField source={CASE_FIELDS.REGISTRATION_DATE}/>
            <TextField source={CASE_FIELDS.REGISTRATION_NUMBER}/>
            <DateField source={CASE_FIELDS.PUBLICATION_DATE}/>
            <TextField source={CASE_FIELDS.PUBLICATION_NUMBER}/>
            <TextField source={CASE_FIELDS.STATUS}/>

            <DateField source={CASE_FIELDS.FIRST_PRIORITY_FILING_DATE}/>
            <TextField source={CASE_FIELDS.PRIORITY_APPLICATION_NUMBER}/>
            <FunctionField
                label="Priority Country"
                render={(record: any) => record[CASE_FIELDS.PRIORITY_COUNTRY_CODE]?.toUpperCase()}
            />

            <TextField source={CASE_FIELDS.PRODUCT}/>
            <TextField source={CASE_FIELDS.DEPARTMENT}/>
            <CitationsField label={"Citations"}/>
            <FunctionField
                label={"Priority claims"}
                render={(record: any) => <span>{(record?.priority_claims || []).map(
                    (c:any) => <Chip label={`${c.priority_country_code} ${c.priority_application_number} ${c.priority_filing_date}`} sx={{ marginBottom: 1 }}></Chip>)
                }</span> }
            ></FunctionField>
            <ReferenceField reference="names" source={"primary_agent_id"} label={"Agent"}>
                <TextField source={"name"}/>
            </ReferenceField>
            <ReferenceField reference="names" source={"local_agent_id"} label={"Local Agent"}>
                <TextField source={"name"}/>
            </ReferenceField>
            <DateField source={"expiration_date"} />
            <FunctionField
                label="Next Annuity"
                render={(record: any) => {
                    const nextRenewal = record["next_maintenance_case_action"]?.[0];
                    const dueDate = nextRenewal?.due_date;
                    const graceDate = nextRenewal?.with_fine_due_date;

                    const isGrace = graceDate ?
                        !!moment(dueDate).isBefore(moment()) : false;

                    return isGrace ?
                        <Chip size={"small"} label={
                            <Tooltip title={
                                <Stack sx={{ fontSize: "1rem", padding: 2 }} spacing={1}>
                                    <Box>The original due date ({dueDate ? moment(dueDate).format(dateFormat) : "N/A"}) has passed.</Box>
                                    {
                                        graceDate ?
                                            <Box>The grace due date ({ graceDate ? moment(graceDate).format(dateFormat) : "N/A"}) shown here is the absolute last day for paying the annuity/renewal.</Box> :
                                            <Box>It looks like the system is missing the grace period due date. Please contact us at post@breezeip.com and we will look into it promptly</Box>
                                    }

                                </Stack>
                            }>
                                <Box>Grace: {graceDate ? <DateField source={"next_maintenance_case_action.[0].with_fine_due_date"}></DateField> : "N/A"} </Box>
                            </Tooltip>
                        } />
                    :
                    <DateField source={"next_maintenance_case_action.[0].due_date"} label={"Next renewal"}></DateField>;
                }}
            />
            <FunctionField
                label={"Renewal mode"}
                source={"calc_maintenance_actions_and_fees"}
                render={(record: any) => record?.calc_maintenance_actions_and_fees ? "On" : "Off" }
            ></FunctionField>
            <FunctionField source={"calc_maintenance_actions_and_fees"}
                           render={(record: any) => <span>{(record?.priority_claims || []).map(
                               (c:any) => <Chip label={`${c.priority_country_code} ${c.priority_application_number} ${c.priority_filing_date}`} sx={{ marginBottom: 1 }}></Chip>)
                           }</span> }
            />
            {/*<ReferenceField label="Local Agent" source="local_agent_id" reference="names">*/}
            {/*    <ChipField source="name" />*/}
            {/*</ReferenceField>*/}
            <WrapperField label="Actions">
                <EditButton/>
                <ShowButton label={"Details"}/>
                <CaseActionsButton/>
                <CaseDocumentsButton/>
                <SelectCountriesButton />
                {isAdmin && <CopyCaseButton label={"Copy"}/>}
                {/*{isAdmin && <CreateActionsButton />}*/}
                {isAdmin && <CreateActionsButton />}
                {isAdmin && <SyncWithEPOButton />}
                {isAdmin && <RecreateActionsButton />}
            </WrapperField>
        </DatagridConfigurable>
    );
};

export const CaseActionsButton = (props: any) => {
    const record = useRecordContext();
    return (
        <Button
            label={"Annuities"}
            component={Link}
            to={`${record.id}/show/actions`}
            {...props}
        >
        </Button>
    );
}

export const CaseDocumentsButton = (props: any) => {
    const record = useRecordContext();
    const isAdmin = useIsAdmin();
    const { tenant } = React.useContext(TenantContext) || {};
    const documents = tenant?.feature_documents || isAdmin;
    return documents ? (
        <Button
            {...props}
            label={"Documents"}
            component={Link}
            to={`${record.id}/show/documents`}
        >
        </Button>
    ) : null;
}

const PCT_COUNTRY_CODE = "pc";
const EP_COUNTRY_CODE = "ep";
export const SelectCountriesButton = (props: any) => {
    const record = useRecordContext();
    if (!record) return null;
    if (record.country_code !== PCT_COUNTRY_CODE && record.country_code !== EP_COUNTRY_CODE) return null;

    const priorityDate = record.first_priority_filing_date ? moment(record.first_priority_filing_date) : null;
    const filingDate = record.filing_date ? moment(record.filing_date) : null
    const date = priorityDate?.isBefore(filingDate) ? priorityDate : filingDate;
    // if (!date) return null;

    const designatedStates = record.designated_states?.split(" ");
    if (!designatedStates || designatedStates.length === 0) return null;

    return (
        <Button
            {...props}
            label={"Select countries"}
            component={Link}
            to={`${record.id}/select-countries`}
        >
        </Button>
    );
}


