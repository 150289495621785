import { Alert } from "@mui/material"
import { Stack } from "@mui/system"
import CheckIcon from '@mui/icons-material/Check';

export const HappyEmpty = () => {
    return (
        // <Box style={{ fontSize: "100px"}}>
        <Stack sx={{
            mt: 10,
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
        }}>
            <Stack direction={"row"} style={{ fontSize: "100px"}}>
                <span>&#128513;</span>
                <span>&#128513;</span>
                <span>&#128513;</span>
            </Stack>
            <Alert icon={<CheckIcon fontSize="inherit" />} severity="success">
                Congratulations! You're all done here.
            </Alert>
        </Stack>
        // </Box>
    )
}
