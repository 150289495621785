import {Box, Dialog, DialogContent, DialogTitle, Stack } from "@mui/material";
import Mustache from "mustache";
import { useState } from "react";
import { Button, useNotify, useDataProvider, useListContext, required, Form, useRefresh } from "react-admin";
import { countries } from "../../utils/countries";
import {
    DefaultEditorOptions,
    RichTextInput,
} from 'ra-input-rich-text';
import Table from '@tiptap/extension-table'
import TableCell from '@tiptap/extension-table-cell'
import TableHeader from '@tiptap/extension-table-header'
import TableRow from '@tiptap/extension-table-row'
import React from "react";
import {useTenantContext} from "../../react-admin-overrides/AppLayout";
import {useUserId} from "../../auth/utils";
import moment from "moment";
import {dateOrNull} from "../crud/CaseSyncWithEPO";

export const HANDLER_ACUMASS = "Acumass";

export const SendToAcumassEmailButton = () => {
    const [open, setOpen] = useState(false);
    const [emailPreview, setEmailPreview] = useState("");
    const [emailData, setEmailData] = useState<any>(null);
    const dataProvider = useDataProvider();
    const notify = useNotify();
    const { selectedIds, data, onUnselectItems } = useListContext();
    const { tenant } = useTenantContext()  || {};
    const userId = useUserId();
    const refresh = useRefresh();

    const selected = data.filter((d: any) => selectedIds.includes(d.id));
    const selectedCaseActionIds = selected.map((d: any) => d.case_action_id);

    const handleClick = async () => {
        // TODO: Verify that the selection does not contain items from two different tenants -> Show error if it does

        try {
            // Fetch email template and preview
            const response = await dataProvider.getOne('email_templates', {
                id: '108576c3-31bc-4430-8142-a1634ec9ab15' // You'll need to create this template
            });

            const sendingUserResponse = await dataProvider.getOne('users', {id: userId});

            console.log(sendingUserResponse);

            const effectiveDueDate = (row: any) => {
                const dueDate = row.case_action?.due_date;
                const graceDueDate = row.case_action?.with_fine_due_date;

                if (graceDueDate) {
                    return moment(dueDate).isAfter(moment(), "day") ? {
                        dueDate,
                        isGrace: false,
                    } : {
                        dueDate: graceDueDate,
                        isGrace: dueDate !== graceDueDate,
                    };
                }
                return {
                    dueDate,
                    isGrace: false,
                };
            }

            const htmlTable = `
                <table border="1" cellpadding="5" style="border-collapse: collapse; border: 1px;">
                    <tbody>
                        <tr>
                            <th>Applicant</th>
                            <th>Breeze Case Ref</th>
                            <th>Country</th>
                            <th>Code</th>
                            <th>App. #</th>
                            <th>Filing date</th>
                            <th>Int. filing date</th>
                            <th>Reg. date</th>
                            <th>Reg. #</th>
                            <th>Type</th>
                            <th>Annuity/Renewal</th>
                            <th>Due Date</th>
                            <th>Is grace</th>
                            <th>Instruction</th>
                        </tr>
                        ${selected.map(row => `
                            <tr>
                                <td>${row.case_action?.case?.applicant || ''}</td>
                                <td>${row.case_action?.case?.case_ref || ''}</td>
                                <td>${countries[row.case_action?.case?.country_code] || ''}</td>
                                <td>${row.case_action?.case?.country_code?.toUpperCase() || ''}</td>
                                <td>${row.case_action?.case?.application_number || ''}</td>
                                <td>${dateOrNull(row.case_action?.case?.filing_date) || ''}</td>
                                <td>${dateOrNull(row.case_action?.case?.international_filing_date) || ''}</td>
                                <td>${dateOrNull(row.case_action?.case?.registration_date) || ''}</td>
                                <td>${row.case_action?.case?.registration_number || ''}</td>
                                <td>${row.case_action?.case?.case_ref?.startsWith("D") ? "Design" : "Patent"}</td>
                                <td>${row.case_action?.action_rule?.action_name || ''}</td>
                                <td>${effectiveDueDate(row).dueDate || ''}</td>
                                <td>${effectiveDueDate(row).isGrace ? "Yes" : 'No'}</td>
                                <td>${row.instruction || ''}</td>
                            </tr>
                        `).join('')}
                    </tbody>
                </table>
            `;

            
            const recipientName = "Acumass";
            const recipientEmail = "annuities@acumass.com";
            // const recipientEmail = "aleksander@breezeip.com";

            const emailPreview = Mustache.render(response.data?.body?.replaceAll("\n", "<div></div>") || '', {
                table: htmlTable,
                recipientName,
                senderName: sendingUserResponse.data?.name || "Breeze IP",
            }, {}, { escape: (text) => text });

            const emailSubject = Mustache.render(response.data?.subject || '', { recipientName });

            setEmailPreview(emailPreview);
            const recipientEmails = [recipientEmail];
            const mainRecipient = recipientEmails[0];
            const otherRecipients = recipientEmails.slice(1);

            const recipientCcEmails = [...(tenant?.cc_email ? [tenant?.cc_email] : []), ...otherRecipients];
            const BREEZE_EMAIL = "post@breezeip.com";
            setEmailData({
                type: "INSTRUCTIONS_CONFIRMED_BY_BREEZE",
                sending_status: "INITIATED_BY_BREEZE",
                from_user_id: userId,
                subject: emailSubject,
                body: emailPreview,
                recipient_email: recipientEmail,
                recipient_cc_emails: ["janove@breezeip.com"],
                recipient_bcc_emails: [mainRecipient, ...recipientCcEmails].includes(BREEZE_EMAIL) ? [] : [BREEZE_EMAIL],
            });
            setOpen(true);
        } catch (error) {
            notify('Error loading email preview', { type: 'error' });
        }
    };

    const handleSend = async (formData: any) => {
        console.log(emailData, formData);
        try {
            const { data: createdEmail } = await dataProvider.create('emails', {
                data: emailData
            });


            await dataProvider.updateMany('instructions_received', {
                ids: selectedIds,
                data: {
                    acumass_email_id: createdEmail.id,
                    handler: HANDLER_ACUMASS,
                }
            });

            notify('Email sent successfully', { type: 'success' });
            setOpen(false);
            onUnselectItems();
            refresh();
        } catch (error) {
            notify('Error sending email', { type: 'error' });
        }
    };

    return (
        <>
            <Button
                label="Send Acumass email"
                onClick={handleClick}
                disabled={selectedIds.length === 0}
            />
            
            <Dialog open={open} onClose={() => setOpen(false)} maxWidth="xl" fullWidth>
                <DialogTitle>Send Acumass email</DialogTitle>
                <DialogContent>

                    <Form onSubmit={handleSend}>
                        <Stack spacing={1} mb={2}>
                           <Box><strong>To:</strong> {emailData?.recipient_email}</Box>
                           <Box><strong>CC:</strong> {emailData?.recipient_cc_emails?.join(", ")}</Box>
                           <Box><strong>BCC:</strong> {emailData?.recipient_bcc_emails?.join(", ")}</Box>
                        </Stack>
                       <Box mb={5}><strong>Subject:</strong> {emailData?.subject}</Box>
                       <Box mb={2}><strong>Email:</strong></Box>
                        <div dangerouslySetInnerHTML={{ __html: emailPreview }} />
                        {/*<RichTextInput*/}
                        {/*    source="body"*/}
                        {/*    defaultValue={emailPreview}*/}
                        {/*    fullWidth*/}
                        {/*    label={false}*/}
                        {/*    validate={[required()]}*/}
                        {/*    editorOptions={{*/}
                        {/*       ...MyEditorOptions,*/}
                        {/*       onCreate: ({ editor }: { editor: any }) => {*/}
                        {/*           editorRef.current = editor;*/}
                        {/*       },*/}
                        {/*   }}*/}
                        {/*/>*/}

                        <Button
                            label="Send Acumass Email"
                            type={"submit"}
                            sx={{ mt: 2 }}
                        />
                    </Form>
                </DialogContent>
            </Dialog>
        </>
    );
};

export const MyEditorOptions = {
    ...DefaultEditorOptions,
    extensions: [
        // @ts-ignore
        ...DefaultEditorOptions.extensions,
        Table,
        TableCell,
        TableHeader,
        TableRow
    ],
};
