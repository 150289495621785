import React, { useState } from "react";
import { useDataProvider } from "react-admin";
import {CasePreview} from "../cases/import/importCases";

export type HasuraRequestBody = {
    query?: string,
    mutation?: string,
    variables: object | undefined
}
export const useHasuraRequest = () => {
    const dataProvider = useDataProvider();

    const [loading, setLoading] = useState(false)
    const [data, setData] = useState<any>(null)
    const [error, setError] = useState(null)

    const makeApiCall = (body: HasuraRequestBody) => {
        setLoading(true);
        setError(null);
        setData(null);


        dataProvider.hasuraRequest(body)
            .then((responseBody: any) => {
                setLoading(false);
                if (responseBody.data) {
                    setData(responseBody.data);
                } else {
                    console.error(responseBody);
                    setError(responseBody.errors);
                }
            })
            .catch((error: any) => {
                console.error(error);
                setError(error);
                setLoading(false);
            });
    };


    return { loading, data, error, makeApiCall }
}