import React, {useState} from "react";
import { Button } from '@mui/material';
import { useRecordContext, useDataProvider, useListContext } from 'react-admin';
import { useNavigate } from 'react-router';
import {Spinner} from "../../utils/Spinner";
import {useTenantId} from "../../data/useTenantId";
import {useUserId} from "../../auth/utils";
import {TenantContext} from "../../react-admin-overrides/AppLayout";

export const SendPaymentInstructionsButton = () => {
    const tenantId = useTenantId();
    const userId = useUserId();
    const { selectedIds, data } = useListContext();
    const dataProvider = useDataProvider();
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();
    const { tenant } = React.useContext(TenantContext) || {};

    const onClick = (e: any) => {
        e?.preventDefault();
        setIsLoading(true);
        dataProvider.createAnnuitiesOrder(selectedIds, tenantId, tenant?.currency_code)
            .then((body: any) => {
                navigate(`/annuity_orders/${body.id}/show`)
            })
            .catch(console.error);
    }

    if (isLoading) return <Spinner/>;

    return (
        <Button
            onClick={onClick}
        >
            Annuities Management
        </Button>
    );
};
