import { Alert, AlertTitle } from "@mui/material";
import { BulkUpdateFormButton } from "@react-admin/ra-form-layout";
import moment from "moment";
import {DateTimeInput, TextInput, SimpleForm, SaveButton, Toolbar, useRecordContext, useListContext } from "react-admin";
import {useUserId} from "../auth/utils";


export const MarkDocsFromAgentReceived = (props: any) => {
    const userId = useUserId();
    const now = moment();
    return (
        <BulkUpdateFormButton label={"Mark documents from Agent received"} icon={<></>}>
            <SimpleForm
                toolbar={
                    <Toolbar>
                        <SaveButton label={"Save"} alwaysEnable />
                    </Toolbar>
                }
                sx={{maxWidth: 600}} defaultValues={{
                agent_documents_received_at: now,
                agent_documents_received_by: userId,
            }}>
                <Alert variant="outlined" severity="info">
                    <AlertTitle>Mark documents from Agent received</AlertTitle>
                    The order lines will be moved to the next step, and a timestamp + who did it will be recorded. Nothing else changes.
                </Alert>
            </SimpleForm>
        </BulkUpdateFormButton>
    );
}
