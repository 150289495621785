import * as React from 'react';
import { FunctionComponent } from 'react';
import {CloneButton, CreateButton, useRecordContext } from "react-admin";


export const transformRemoveDBAutoFields = (record: any) => {
    const {id, created_at, updated_at, ...restRecord} = record || {};
    return restRecord;
};

export const CloneWithTransformation: FunctionComponent<{ transform: (record: any) => any }> = ({ transform }) => {
    const record = useRecordContext();
    const createData = transform(record);
    return (
        <CloneButton
            record={createData}
        />
    );
};
