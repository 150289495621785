export const FIRST_OF_FPD_OR_FD = "first_of_first_priority_date_or_filing_date";
export const INT_FILING_DATE_OR_FILING_DATE = "international_filing_date_or_filing_date";
export const REGISTRATION_DATE_AND_IFD = "registration_date_and_ifd";
export const REGISTRATION_DATE_AND_IFD_OR_FD = "registration_date_and_ifd_or_fd";
export const REGISTRATION_DATE_AND_FILING_DATE = "registration_date_and_filing_date";

export const REGISTRATION_DATE_AND_PUBLICATION_DATE = "registration_date_and_publication_date";

export const FILING_DATE = "filing_date";
export const INTERNATIONAL_FILING_DATE = "international_filing_date";
const REGISTRATION_DATE = "registration_date";
const FIRST_PRIORITY_FILING_DATE = "first_priority_filing_date";
const NATIONAL_FILING_DATE = "national_filing_date";
export const INTENTION_TO_GRANT = "intention_to_grant";
export const triggerDateOptions = [
    FILING_DATE,
    INTERNATIONAL_FILING_DATE,
    INT_FILING_DATE_OR_FILING_DATE,
    REGISTRATION_DATE,
    FIRST_PRIORITY_FILING_DATE,
    FIRST_OF_FPD_OR_FD,
    REGISTRATION_DATE_AND_IFD,
    REGISTRATION_DATE_AND_IFD_OR_FD,
    REGISTRATION_DATE_AND_FILING_DATE,
    NATIONAL_FILING_DATE,
    REGISTRATION_DATE_AND_PUBLICATION_DATE,
    INTENTION_TO_GRANT,
];

export const triggerDateTitles: { [key: string]: string } = {
    [FILING_DATE]: "Filing Date",
    [INTERNATIONAL_FILING_DATE]: "International Filing Date",
    [INT_FILING_DATE_OR_FILING_DATE]: "International Filing Date or Filing Date if not available",
    [REGISTRATION_DATE]: "Registration Date",
    [FIRST_PRIORITY_FILING_DATE]: "First Priority Filing Date",
    [FIRST_OF_FPD_OR_FD]: "First of First Priority Date or Filing Date if not available",
    [REGISTRATION_DATE_AND_IFD_OR_FD]: "Registration Date required -> trigger date is International Filing Date or Filing Date if not available",
    [REGISTRATION_DATE_AND_IFD]: "Registration Date required -> trigger date is International Filing Date",
    [REGISTRATION_DATE_AND_FILING_DATE]: "Registration Date required -> trigger date is Filing Date",
    [NATIONAL_FILING_DATE]: "National Filing Date",
    [REGISTRATION_DATE_AND_PUBLICATION_DATE]: "Registration Date required -> trigger date is Publication Date",
    [INTENTION_TO_GRANT]: "Intention to grant date",
};