import * as React from "react";
import {
    AutocompleteInput,
    BulkDeleteButton,
    Button,
    CreateButton,
    Datagrid,
    DatagridConfigurable,
    DateField,
    EditButton,
    ExportButton,
    Filter,
    FilterButton,
    FunctionField,
    ImageField,
    Link,
    List,
    Pagination,
    ReferenceField,
    ReferenceInput,
    ReferenceManyField,
    ReferenceOneField,
    SelectInput,
    ShowButton,
    SimpleForm,
    TextField,
    TextInput,
    TopToolbar,
    useRecordContext,
    useResourceContext,
    useUnselectAll,
    WrapperField
} from 'react-admin';
import {BulkUpdateFormButton} from '@react-admin/ra-form-layout';
import WorkIcon from '@mui/icons-material/Work';
import PublishIcon from '@mui/icons-material/Publish';
import {CaseActionsList} from "./crud/CaseActionsList";
import {countries, countriesOptions} from "../utils/countries";
import {CopyCaseButton} from "./CopyCaseButton";
import {useNavigate} from "react-router";
import {useIsAdmin} from "../auth/utils";
import {ApplicantNameInput} from "./crud/ApplicantNameInput";
import {CreateActionsButton} from "./actions/CreateActionsButton";
import {Page} from "../utils/Page";
import {TenantField} from "../utils/tenant";
import { CountryInput } from "../utils/CountryInput";
import {alternateBackgroundColor, CASE_FIELDS, compactTable} from "./CaseList";
import {dateFormat} from "./actions/Actions";
import moment from "moment";
import {DepartmentInput, ProductInput} from "./crud/CaseInputFields";
import {ANNUITY, RENEWAL} from "./actions/utils";
import {CaseRefField} from "./crud/CaseRefField";
import {ApplicationNumberField} from "./crud/AppplicationNumberField";

const OtherCasesFilter = (props: any) => (
    <Filter {...props}>
        <CountryInput label={"Country"} source={"country_code"}/>
        <TextInput source="case_ref"></TextInput>
        <TextInput source="application_number"></TextInput>
        <TextInput source="catchword"></TextInput>
        <TextInput source="registration_number"></TextInput>
        <TextInput source="applicant"></TextInput>
        <ProductInput source={"product"}></ProductInput>
        <DepartmentInput source={"department"}></DepartmentInput>
        <ReferenceInput reference="tenants" source="tenant_id" label="Client">
            <AutocompleteInput filterToQuery={(searchText: string) => ({name: `${searchText}`})} label={"Client"} />
        </ReferenceInput>
    </Filter>
);

const OtherCasesActions = () => {
    const isAdmin = useIsAdmin();
    const commonFilters = [
        <CountryInput label={"Country"} source={"country_code"}/>,
        <TextInput source="case_ref"></TextInput>,
        <TextInput source="application_number"></TextInput>,
        <TextInput source="registration_number"></TextInput>,
        <TextInput source="applicant"></TextInput>,
        <ProductInput source={"product"}></ProductInput>,
    ];

    const filters = isAdmin ? [
        ...commonFilters,
        <ReferenceInput reference="tenants" source="tenant_id" label="Client">
            <AutocompleteInput filterToQuery={(searchText: string) => ({name: `${searchText}`})} label={"Client"} />
        </ReferenceInput>,
    ] : commonFilters;
    return (
        <TopToolbar>
            <FilterButton filters={filters}/>
            {isAdmin && <CreateButton/>}
            {isAdmin && <ExportButton/>}
        </TopToolbar>
    );
};

const OtherCasesBulkUpdateButton = () => (
    <BulkUpdateFormButton>
        <SimpleForm sx={{maxWidth: 400}}>
            <ApplicantNameInput/>
            <ReferenceInput source="tenant_id" reference="tenants">
                <AutocompleteInput filterToQuery={(searchText: string) => ({name: `${searchText}`})} label="Client"
                                   fullWidth/>
            </ReferenceInput>
        </SimpleForm>
    </BulkUpdateFormButton>
);

const bulkActionButtons: React.ReactElement = <>
    <OtherCasesBulkUpdateButton/>
    <BulkDeleteButton mutationMode="pessimistic"/>
</>;

export const OtherCasesList = (props: any) => {
    const isAdmin = useIsAdmin();
    const unselectAll = useUnselectAll("cases");
    React.useEffect(() => {
        unselectAll()
    }, []);

    const config = {
        bulkActions: isAdmin ? bulkActionButtons : false as false,
        rowClick: isAdmin ? "expand" : undefined,
        expand: isAdmin ?
            <div>
                <h3>Actions</h3>
                <ReferenceManyField label="Actions" reference="case_actions" target="case_id"
                                    sort={{field: "due_date", order: "ASC"}} filter={{ "action_rule#action_type@_neq": ANNUITY }}>
                <CaseActionsList />
                </ReferenceManyField>
            </div> : undefined
    }

    return (
        <Page title={props.title || "Other cases"} body={<>
        </>}>
            <List
                  resource={"cases"}
                  sort={{field: "case_ref", order: "ASC"}}
                  filter={{ "case_type@_eq": props.caseType }}
                  filters={<OtherCasesFilter/>}
                  actions={<OtherCasesActions/>}
                  perPage={100}
                  pagination={<Pagination rowsPerPageOptions={[10, 25, 50, 100]} />}
                  storeKey={"trademarks"}
                  {...props}
            >
                <OtherCasesDatagrid config={config}></OtherCasesDatagrid>
            </List>
        </Page>
    );
};

export const OppositionCasesList = (props: any) =>
    <OtherCasesList title={"Oppositions"}  storeKey={"opposition"} caseType={"O"} />


export const InfringementCasesList = (props: any) =>
    <OtherCasesList title={"Infringements"}  storeKey={"infringement"} caseType={"I"} />

export const OtherCasesDatagrid = (props: any) => {
    const isAdmin = useIsAdmin();
    return (
        <DatagridConfigurable omit={["id", "primary_agent_id", "local_agent_id", "created_at"]} bulkActionButtons={props.config.bulkActions} rowClick={props.config.rowClick} rowSx={alternateBackgroundColor} sx={compactTable}
                              expand={props.config.expand}>
            {/*<TextField source="id" />*/}
            {isAdmin && <TenantField label={"Client"}/>}

            <CaseRefField source={CASE_FIELDS.CASE_REF}></CaseRefField>
            <FunctionField
                label="Country"
                render={(record: any) => countries[record[CASE_FIELDS.COUNTRY_CODE]]}
            />
            <TextField source={CASE_FIELDS.CATCHWORD}/>
            <TextField source={CASE_FIELDS.APPLICATION_TYPE}/>
            <TextField source={CASE_FIELDS.PRODUCT}/>
            <TextField source={CASE_FIELDS.DEPARTMENT}/>
            <WrapperField label="Actions">
                <EditButton/>
                <ShowButton label={"Details"}/>
                <ActionsButton/>
                <DocumentsButton/>
            </WrapperField>
        </DatagridConfigurable>
    );
};

const DocumentsButton = (props: any) => {
    const record = useRecordContext();
    return (
        <Button
            {...props}
            label={"Documents"}
            component={Link}
            to={`/cases/${record.id}/show/documents`}
        >
        </Button>
    );
}


const ActionsButton = (props: any) => {
    const record = useRecordContext();
    return (
        <Button
            label={"Actions"}
            component={Link}
            to={`/cases/${record.id}/show/actions`}
            {...props}
        >
        </Button>
    );
}

