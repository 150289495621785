import { BulkUpdateFormButton } from "@react-admin/ra-form-layout";
import moment from "moment";
import {DateTimeInput, TextInput, SimpleForm, SaveButton, Toolbar, useRecordContext, useListContext, FormDataConsumer } from "react-admin";
import {useUserId} from "../../auth/utils";
import {triggerDateTitles} from "../../actions/triggerDate";


export const MarkInvoiceSent = () => {
    const userId = useUserId();
    const now = moment();
    return (
        <BulkUpdateFormButton label={"Mark as invoiced"} icon={<></>} >
            <SimpleForm
                toolbar={
                    <Toolbar>
                        <SaveButton label={"Save"} alwaysEnable />
                    </Toolbar>
                }
                sx={{maxWidth: 400}} defaultValues={{
                invoiced_by: userId,
                invoiced_at: now,
            }}>
                <DateTimeInput source={"invoiced_at"} label={"Invoiced at"} defaultValue={moment().format()} parse={(val: any) => moment(val).format()} ></DateTimeInput>
            </SimpleForm>
        </BulkUpdateFormButton>
    );
}
