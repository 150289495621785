import * as React from 'react';
import { FunctionComponent } from 'react';
import {Link, LinkProps, useRecordContext } from 'react-admin';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';

type CaseLinkProps = Omit<LinkProps, 'to'> & {
    caseId: string;
    linkText: string;
    showIcon?: boolean;
};

export const CaseLink: FunctionComponent<CaseLinkProps> = ({ caseId, linkText, showIcon, ...linkProps }) => (
    <Link {...linkProps} to={`/cases/${caseId}/show`}>{linkText}{showIcon ? <span> <OpenInNewIcon fontSize={"small"} sx={{verticalAlign: "bottom"}}></OpenInNewIcon></span> : null}</Link>
);


export const CaseRefField = (props: any) => {
    const record = useRecordContext(props);
    const idSource = props.idSource || "id";
    const source = props.source || "case_ref";
    return record ? <CaseLink caseId={record[idSource]} linkText={record[source]}></CaseLink> : null;
};

CaseRefField.defaultProps = { label: 'Case ref' };
