import React, {FunctionComponent, useState } from 'react';
import { Button, Dialog, DialogTitle, DialogContent, DialogActions, Alert, AlertTitle, Typography } from '@mui/material';
import {CloseDialogIconButton} from "./CloseDialogIconButton";
import { Link } from 'react-admin';

export const SuccessDialog: FunctionComponent = () => {
    const [open, setOpen] = useState(true);

    const onClick = (e: any) => {
        e?.preventDefault();
        setOpen(true);
    }
    const close = (e: any) => {
        e?.preventDefault();
        setOpen(false)
    };

    return (
        <Dialog open={open} fullWidth maxWidth="xl">
            {/*<CloseDialogIconButton onClose={close}/>*/}
            <DialogTitle>Order submitted successfully</DialogTitle>
            <DialogContent>
                <Alert severity="success" sx={{marginBottom: 2}}>
                    <AlertTitle>Success</AlertTitle>
                    <Typography>All good! You will receive a confirmation by email when we have verified that everything is okay.</Typography>
                </Alert>
            </DialogContent>
            <DialogActions>
                <Button component={Link}
                        to={`/`}
                >Go to home page</Button>
            </DialogActions>
        </Dialog>
    );
}
