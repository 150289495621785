import * as React from "react";
import { BulkDeleteButton, TextField, DateField, WithRecord, Button, Datagrid, ListContextProvider, ReferenceManyField, FunctionField, List, ReferenceField, TextInput, SelectInput, ReferenceInput, AutocompleteInput, SimpleForm, NumberInput, Pagination, BulkExportButton, ReferenceArrayInput, Link, Count, WrapperField, SingleFieldList, ReferenceManyCount, WithListContext, useUnselectAll, NumberField } from "react-admin";
import {countries} from "../../utils/countries";
import {Page} from "../../utils/Page";
import {TenantField} from "../../utils/tenant";
import {CountryInput} from "../../utils/CountryInput";
import {DepartmentInput, ProductInput} from "../crud/CaseInputFields";
import {toChoices} from "../../common/common-utils";
import {INSTRUCTION_DO_NOT_PAY, INSTRUCTION_PAY, INSTRUCTION_WAIT} from "../annuities/Annuities";
import {Flag} from "../../utils/Flag";
import {Alert, Box, Card, DialogContent, Stack, Tab } from "@mui/material";
import { EditInDialogButton, ShowInDialogButton } from "@react-admin/ra-form-layout";
import {ConfirmInstructionsButton} from "./ConfirmInstructions";
import {MarkPaidButton} from "./MarkPaid";
import { Tabs } from "@mui/material";
import {instructionsExportToExcel} from "./instructions-exporter";
import moment from "moment";
import { SendConfirmationEmailButton } from "./SendConfirmationEmailButton";
import {MarkInvoiceSent} from "./MarkInvoiceSent";
import {HANDLER_BREEZE, SetBreezeAsHandlerButton} from "./SetBreezeAsHandlerButton";
import {HANDLER_ACUMASS, SendToAcumassEmailButton} from "./SendToAcumassEmailButton";
import {CASE_STATUS_CLOSED} from "../crud/utils";
import {emojis} from "../../utils/utils";
import {ANNUITY, RENEWAL} from "../actions/utils";
import {AnnuityCaseActionsList} from "../crud/CaseActionsList";
import {dateFormat} from "../actions/Actions";
import {norwegianInstructionsExportToExcel} from "./norwegian-instructions-exporter";
import {invoiceExportToExcel} from "./invoice-data-exporter";
import { HappyEmpty } from "../../utils/HappyEmpty";

const Filter = (props: any) => (
    <Filter {...props}>
        <TextInput source="instruction" alwaysOn></TextInput>
    </Filter>
);


const bulkActionButtons = <>
    {/*<BulkDeleteButton mutationMode="pessimistic" />*/}
    <ConfirmInstructionsButton></ConfirmInstructionsButton>
    <MarkPaidButton></MarkPaidButton>
</>;

const invoiceFilter = {
    "instruction@_nin": [INSTRUCTION_WAIT, INSTRUCTION_DO_NOT_PAY],
    "invoiced_at@_is_null": true,
};

const receivedFilter = {
    "instruction@_neq": INSTRUCTION_WAIT,
    "case_action#instructions_confirmed_by_payment_agent_at@_is_null": true,
    "case_action#completed_at@_is_null": true,
};

const confirmedFilter = {
    "instruction@_eq": INSTRUCTION_PAY,
    "case_action#instructions_confirmed_by_payment_agent_at@_is_null": false,
    "case_action#completed_at@_is_null": true,
    "handler@_is_null": true,
};

const doNotPayFilter = {
    "instruction@_eq": INSTRUCTION_DO_NOT_PAY,
    "case_action#instructions_confirmed_by_payment_agent_at@_is_null": false,
    "case_action#completed_at@_is_null": true,
};
const acumassFilter = {
    "instruction@_eq": INSTRUCTION_PAY,
    "case_action#instructions_confirmed_by_payment_agent_at@_is_null": false,
    "case_action#completed_at@_is_null": true,
    "handler@_eq": HANDLER_ACUMASS,
};

const breezeFilter = {
    "instruction@_eq": INSTRUCTION_PAY,
    "case_action#instructions_confirmed_by_payment_agent_at@_is_null": false,
    "case_action#completed_at@_is_null": true,
    "handler@_eq": HANDLER_BREEZE,
};

export const InstructionsReceived = (props: any) => {
    const [value, setValue] = React.useState("one");
    const unselectAll = useUnselectAll("instructions_received");
    React.useEffect(() => {
        unselectAll()
    }, []);
    const handleChange = (event: React.SyntheticEvent, newValue: string) => {
        setValue(newValue);
        unselectAll();
    }


    return (
        <Page title={"Annuity & Renewal orders"}>
            <Tabs
                value={value}
                onChange={handleChange}
            >
                <Tab
                    value="one"
                    label={<Box>Received <Count filter={receivedFilter}></Count></Box>}
                />
                <Tab value="confirmed" label={<Box>Confirmed <Count filter={confirmedFilter}></Count></Box>} />
                <Tab value="acumass" label={<Box>Sent to Acumass <Count filter={acumassFilter}></Count></Box>} />
                <Tab value="breeze" label={<Box>Breeze Direct Payment <Count filter={breezeFilter}></Count></Box>} />
                <Tab value="donotpay" label={<Box>Do not pay <Count filter={doNotPayFilter}></Count></Box>} />
                <Tab value="three" label="Instructions Paid/Completed" />
                <Tab value="all" label="ALL" />
                <Tab value="invoice" label={<Box>To be invoiced <Count filter={invoiceFilter}></Count></Box>} />
                <Tab value="invoice-history" label="Invoice history" />
            </Tabs>
            {
                value === "one" &&
                <List {...props}
                      sort={{ field: "created_at", order: "DESC" }}
                      filters={[
                          <ReferenceArrayInput label="Instruction" reference="instructions_received_event" source="instructions_received_event_id" sort={{field: "created_at", order: "DESC" }} alwaysOn>
                              <SelectInput label={"Instruction"} optionText={(record: any) => `${moment(record.created_at).format("DD.MM.YY HH:mm")} – ${record.instructions_received?.[0]?.case_action?.case?.tenant?.name || ""} – ${record.user?.name || ""}`}/>
                          </ReferenceArrayInput>,
                          <SelectInput source="instruction@_eq" alwaysOn choices={toChoices([INSTRUCTION_PAY, INSTRUCTION_DO_NOT_PAY])}></SelectInput>,
                          <ReferenceInput source="case_action#case#tenant_id" reference="tenants" alwaysOn>
                              <AutocompleteInput filterToQuery={(searchText: string) => ({name: `${searchText}`})} label="Client"
                                                 fullWidth/>
                          </ReferenceInput>,
                      ]}
                      filter={receivedFilter}
                      perPage={50}
                      exporter={instructionsExportToExcel}
                      pagination={<Pagination rowsPerPageOptions={[25, 50, 100, 200]} />}
                      empty={<HappyEmpty/>}
                >
                    <Datagrid bulkActionButtons={
                        <>
                            <SendConfirmationEmailButton></SendConfirmationEmailButton>
                            <ConfirmInstructionsButton></ConfirmInstructionsButton>
                            <BulkExportButton></BulkExportButton>
                        </>
                    }>
                        {commonFields}
                        <EditInDialogButton fullWidth mutationMode={"pessimistic"} title={`Edit instruction`}>
                            <Box sx={{minWidth: "600px"}}>
                                <SimpleForm maxWidth={"sm"}>
                                    <TextInput source={"price_currency"} fullWidth />
                                    <NumberInput source={"official_fee"} fullWidth/>
                                    <NumberInput source={"our_fee"} fullWidth/>
                                    <NumberInput source={"agent_fee"} fullWidth/>
                                    <NumberInput source={"price"} fullWidth/>
                                </SimpleForm>
                            </Box>
                        </EditInDialogButton>
                    </Datagrid>
                </List>
            }

            {
                value === "confirmed" &&
                <List {...props}
                      sort={{ field: "created_at", order: "DESC" }}
                      filters={[
                          <ReferenceArrayInput label="Instruction" reference="instructions_received_event" source="instructions_received_event_id" sort={{field: "created_at", order: "DESC" }} alwaysOn>
                              <SelectInput label={"Instruction"} optionText={(record: any) => `${moment(record.created_at).format("DD.MM.YY HH:mm")} – ${record.instructions_received?.[0]?.case_action?.case?.tenant?.name || ""} – ${record.user?.name || ""}`}/>
                          </ReferenceArrayInput>,
                          <SelectInput source="instruction@_eq" alwaysOn choices={toChoices([INSTRUCTION_PAY, INSTRUCTION_DO_NOT_PAY])}></SelectInput>,
                          <ReferenceInput source="case_action#case#tenant_id" reference="tenants" alwaysOn>
                              <AutocompleteInput filterToQuery={(searchText: string) => ({name: `${searchText}`})} label="Client"
                                                 fullWidth/>
                          </ReferenceInput>,
                      ]}
                      filter={confirmedFilter}
                      perPage={50}
                      exporter={instructionsExportToExcel}
                      pagination={<Pagination rowsPerPageOptions={[25, 50, 100, 200]} />}
                      empty={<HappyEmpty></HappyEmpty>}
                >
                    <Datagrid bulkActionButtons={
                        <>
                            <SetBreezeAsHandlerButton></SetBreezeAsHandlerButton>
                            <SendToAcumassEmailButton></SendToAcumassEmailButton>
                            <BulkExportButton></BulkExportButton>
                        </>
                    }>
                        {commonFields}
                        <ConfirmedField label="Confirmed"></ConfirmedField>
                        <EditInDialogButton fullWidth mutationMode={"pessimistic"} title={`Edit instruction`}>
                            <Box sx={{minWidth: "600px"}}>
                                <SimpleForm maxWidth={"sm"}>
                                    <TextInput source={"price_currency"} fullWidth />
                                    <NumberInput source={"official_fee"} fullWidth/>
                                    <NumberInput source={"our_fee"} fullWidth/>
                                    <NumberInput source={"agent_fee"} fullWidth/>
                                    <NumberInput source={"price"} fullWidth/>
                                </SimpleForm>
                            </Box>
                        </EditInDialogButton>
                    </Datagrid>
                </List>
            }

            {
                value === "donotpay" &&
                <List {...props}
                      sort={{ field: "created_at", order: "DESC" }}
                      filters={[
                          <ReferenceArrayInput label="Instruction" reference="instructions_received_event" source="instructions_received_event_id" sort={{field: "created_at", order: "DESC" }} alwaysOn>
                              <SelectInput label={"Instruction"} optionText={(record: any) => `${moment(record.created_at).format("DD.MM.YY HH:mm")} – ${record.instructions_received?.[0]?.case_action?.case?.tenant?.name || ""} – ${record.user?.name || ""}`}/>
                          </ReferenceArrayInput>,
                          <SelectInput source="instruction@_eq" alwaysOn choices={toChoices([INSTRUCTION_PAY, INSTRUCTION_DO_NOT_PAY])}></SelectInput>,
                          <ReferenceInput source="case_action#case#tenant_id" reference="tenants" alwaysOn>
                              <AutocompleteInput filterToQuery={(searchText: string) => ({name: `${searchText}`})} label="Client"
                                                 fullWidth/>
                          </ReferenceInput>,
                      ]}
                      filter={doNotPayFilter}
                      perPage={50}
                      exporter={instructionsExportToExcel}
                      pagination={<Pagination rowsPerPageOptions={[25, 50, 100, 200]} />}
                      empty={<HappyEmpty></HappyEmpty>}
                >
                    <Datagrid bulkActionButtons={
                        <>
                            <MarkPaidButton></MarkPaidButton>
                            <BulkExportButton></BulkExportButton>
                        </>
                    }>
                        {commonFields}
                        <ConfirmedField label="Confirmed"></ConfirmedField>
                        <FunctionField label={"Case closed?"} render={(record: any) => (
                            <Box sx={{ textWrap: "nowrap"}}>
                                {
                                    record?.case_action?.case?.status === CASE_STATUS_CLOSED ?
                                        <span>{emojis.whiteCheckmark}</span> : <span>{emojis.redCircle}</span>
                                }
                                <span> {record?.case_action?.case?.status}</span>
                            </Box>
                        )}></FunctionField>
                        <WithRecord label="Remaining annuities" render={(record: any) => (
                            <ReferenceManyField label="Actions" reference="case_actions" target="case_id" record={{id: record?.case_action?.case?.id}}
                                                sort={{field: "due_date", order: "ASC"}}
                                                filter={{
                                                    "action_rule#action_type@_in": [ANNUITY, RENEWAL],
                                                    "annuity_payment_instructions_sent_at@_is_null": true,
                                                    "id@_neq": record?.case_action?.id,
                                                }}>
                                <WithListContext render={({ data, isLoading, total }) => (
                                    !isLoading &&
                                        <Box>
                                            <Box sx={{ textWrap: "nowrap"}} mb={2}>{total ? <span>{emojis.redCircle}</span> : <span>{emojis.whiteCheckmark}</span>} {total}</Box>
                                            {
                                                data.map((record: any) => (
                                                    <Box sx={{ textWrap: "nowrap"}}>{moment(record?.due_date).format(dateFormat)}</Box>
                                                ))
                                            }
                                        </Box>
                                )} />
                            </ReferenceManyField>
                        )}></WithRecord>
                        <EditInDialogButton fullWidth mutationMode={"pessimistic"} title={`Edit instruction`}>
                            <Box sx={{minWidth: "600px"}}>
                                <SimpleForm maxWidth={"sm"}>
                                    <TextInput source={"price_currency"} fullWidth />
                                    <NumberInput source={"official_fee"} fullWidth/>
                                    <NumberInput source={"our_fee"} fullWidth/>
                                    <NumberInput source={"agent_fee"} fullWidth/>
                                    <NumberInput source={"price"} fullWidth/>
                                </SimpleForm>
                            </Box>
                        </EditInDialogButton>
                    </Datagrid>
                </List>
            }

            {
                value === "acumass" &&
                <List {...props}
                      sort={{ field: "created_at", order: "DESC" }}
                      filters={[
                          <ReferenceArrayInput label="Instruction" reference="instructions_received_event" source="instructions_received_event_id" sort={{field: "created_at", order: "DESC" }} alwaysOn>
                              <SelectInput label={"Instruction"} optionText={(record: any) => `${moment(record.created_at).format("DD.MM.YY HH:mm")} – ${record.instructions_received?.[0]?.case_action?.case?.tenant?.name || ""} – ${record.user?.name || ""}`}/>
                          </ReferenceArrayInput>,
                          <SelectInput source="instruction@_eq" alwaysOn choices={toChoices([INSTRUCTION_PAY, INSTRUCTION_DO_NOT_PAY])}></SelectInput>,
                          <ReferenceInput source="case_action#case#tenant_id" reference="tenants" alwaysOn>
                              <AutocompleteInput filterToQuery={(searchText: string) => ({name: `${searchText}`})} label="Client"
                                                 fullWidth/>
                          </ReferenceInput>,
                      ]}
                      filter={acumassFilter}
                      perPage={50}
                      exporter={instructionsExportToExcel}
                      pagination={<Pagination rowsPerPageOptions={[25, 50, 100, 200]} />}
                      empty={<HappyEmpty></HappyEmpty>}
                >
                    <Datagrid bulkActionButtons={
                        <>
                            <MarkPaidButton></MarkPaidButton>
                            <BulkExportButton></BulkExportButton>
                        </>
                    }>
                        {commonFields}
                        <ConfirmedField label="Confirmed"></ConfirmedField>
                        <EditInDialogButton fullWidth mutationMode={"pessimistic"} title={`Edit instruction`}>
                            <Box sx={{minWidth: "600px"}}>
                                <SimpleForm maxWidth={"sm"}>
                                    <TextInput source={"price_currency"} fullWidth />
                                    <NumberInput source={"official_fee"} fullWidth/>
                                    <NumberInput source={"our_fee"} fullWidth/>
                                    <NumberInput source={"agent_fee"} fullWidth/>
                                    <NumberInput source={"price"} fullWidth/>
                                </SimpleForm>
                            </Box>
                        </EditInDialogButton>
                    </Datagrid>
                </List>
            }


            {
                value === "breeze" &&
                <List {...props}
                      sort={{ field: "created_at", order: "DESC" }}
                      filters={[
                          <ReferenceArrayInput label="Instruction" reference="instructions_received_event" source="instructions_received_event_id" sort={{field: "created_at", order: "DESC" }} alwaysOn>
                              <SelectInput label={"Instruction"} optionText={(record: any) => `${moment(record.created_at).format("DD.MM.YY HH:mm")} – ${record.instructions_received?.[0]?.case_action?.case?.tenant?.name || ""} – ${record.user?.name || ""}`}/>
                          </ReferenceArrayInput>,
                          <SelectInput source="instruction@_eq" alwaysOn choices={toChoices([INSTRUCTION_PAY, INSTRUCTION_DO_NOT_PAY])}></SelectInput>,
                          <ReferenceInput source="case_action#case#tenant_id" reference="tenants" alwaysOn>
                              <AutocompleteInput filterToQuery={(searchText: string) => ({name: `${searchText}`})} label="Client"
                                                 fullWidth/>
                          </ReferenceInput>,
                      ]}
                      filter={breezeFilter}
                      perPage={50}
                      exporter={instructionsExportToExcel}
                      pagination={<Pagination rowsPerPageOptions={[25, 50, 100, 200]} />}
                      empty={<HappyEmpty></HappyEmpty>}
                >
                    <Datagrid bulkActionButtons={
                        <>
                            <MarkPaidButton></MarkPaidButton>
                            <BulkExportButton exporter={norwegianInstructionsExportToExcel} label={"Export Norwegian PO format"}></BulkExportButton>
                        </>
                    }>
                        {commonFields}
                        <ConfirmedField label="Confirmed"></ConfirmedField>
                        <EditInDialogButton fullWidth mutationMode={"pessimistic"} title={`Edit instruction`}>
                            <Box sx={{minWidth: "600px"}}>
                                <SimpleForm maxWidth={"sm"}>
                                    <TextInput source={"price_currency"} fullWidth />
                                    <NumberInput source={"official_fee"} fullWidth/>
                                    <NumberInput source={"our_fee"} fullWidth/>
                                    <NumberInput source={"agent_fee"} fullWidth/>
                                    <NumberInput source={"price"} fullWidth/>
                                </SimpleForm>
                            </Box>
                        </EditInDialogButton>
                    </Datagrid>
                </List>
            }


            {
                value === "three" &&
                <List {...props}
                      sort={{ field: "created_at", order: "DESC" }}
                      filters={[
                          <ReferenceArrayInput label="Instruction" reference="instructions_received_event" source="instructions_received_event_id" sort={{field: "created_at", order: "DESC" }} alwaysOn>
                              <SelectInput label={"Instruction"} optionText={(record: any) => `${moment(record.created_at).format("DD.MM.YY HH:mm")} – ${record.instructions_received?.[0]?.case_action?.case?.tenant?.name || ""} – ${record.user?.name || ""}`}/>
                          </ReferenceArrayInput>,
                          <SelectInput source="instruction@_eq" alwaysOn choices={toChoices([INSTRUCTION_PAY, INSTRUCTION_DO_NOT_PAY])}></SelectInput>,
                          <ReferenceInput source="case_action#case#tenant_id" reference="tenants" alwaysOn>
                              <AutocompleteInput filterToQuery={(searchText: string) => ({name: `${searchText}`})} label="Client"
                                                 fullWidth/>
                          </ReferenceInput>,
                      ]}
                      filter={{
                          "instruction@_neq": INSTRUCTION_WAIT,
                          "case_action#instructions_confirmed_by_payment_agent_at@_is_null": false,
                          "case_action#completed_at@_is_null": false,
                      }}
                      perPage={50}
                      pagination={<Pagination rowsPerPageOptions={[25, 50, 100, 200]} />}
                      empty={false}
                >
                    <Datagrid bulkActionButtons={false}>
                        {commonFields}
                        <ConfirmedField label="Confirmed"></ConfirmedField>
                        <DateField label="Completed/paid at" source={"case_action.completed_at"} showTime />
                        <TextField source={"case_action.completion_notes"} />
                        <EditInDialogButton fullWidth mutationMode={"pessimistic"} title={`Edit instruction`}>
                            <Box sx={{minWidth: "600px"}}>
                                <SimpleForm maxWidth={"sm"}>
                                    <TextInput source={"price_currency"} fullWidth />
                                    <NumberInput source={"official_fee"} fullWidth/>
                                    <NumberInput source={"our_fee"} fullWidth/>
                                    <NumberInput source={"agent_fee"} fullWidth/>
                                    <NumberInput source={"price"} fullWidth/>
                                </SimpleForm>
                            </Box>
                        </EditInDialogButton>
                    </Datagrid>
                </List>
            }

            {
                value === "all" &&
                <List {...props}
                      sort={{ field: "created_at", order: "DESC" }}
                      filters={[
                          <ReferenceArrayInput label="Instruction" reference="instructions_received_event" source="instructions_received_event_id" sort={{field: "created_at", order: "DESC" }} alwaysOn>
                              <SelectInput label={"Instruction"} optionText={(record: any) => `${moment(record.created_at).format("DD.MM.YY HH:mm")} – ${record.instructions_received?.[0]?.case_action?.case?.tenant?.name || ""} – ${record.user?.name || ""}`}/>
                          </ReferenceArrayInput>,
                          <SelectInput source="instruction@_eq" alwaysOn choices={toChoices([INSTRUCTION_PAY, INSTRUCTION_DO_NOT_PAY])}></SelectInput>,
                          <ReferenceInput source="case_action#case#tenant_id" reference="tenants" alwaysOn>
                              <AutocompleteInput filterToQuery={(searchText: string) => ({name: `${searchText}`})} label="Client"
                                                 fullWidth/>
                          </ReferenceInput>,
                      ]}
                      filter={{
                          // "instruction@_neq": INSTRUCTION_WAIT,
                      }}
                      perPage={50}
                      pagination={<Pagination rowsPerPageOptions={[25, 50, 100, 200]} />}
                >
                    <Datagrid bulkActionButtons={
                        false
                        // <BulkDeleteButton></BulkDeleteButton>
                    }>
                        {commonFields}
                        <ConfirmedField label="Confirmed"></ConfirmedField>
                        <DateField label="Completed/paid at" source={"case_action.completed_at"} showTime />
                        <TextField source={"case_action.completion_notes"} />
                        <EditInDialogButton fullWidth mutationMode={"pessimistic"} title={`Edit instruction`}>
                            <Box sx={{minWidth: "600px"}}>
                                <SimpleForm maxWidth={"sm"}>
                                    <TextInput source={"price_currency"} fullWidth />
                                    <NumberInput source={"official_fee"} fullWidth/>
                                    <NumberInput source={"our_fee"} fullWidth/>
                                    <NumberInput source={"agent_fee"} fullWidth/>
                                    <NumberInput source={"price"} fullWidth/>
                                </SimpleForm>
                            </Box>
                        </EditInDialogButton>
                    </Datagrid>
                </List>
            }


            {
                value === "invoice" &&
                <List {...props}
                      sort={{ field: "created_at", order: "DESC" }}
                      filters={[
                          <ReferenceArrayInput label="Instruction" reference="instructions_received_event" source="instructions_received_event_id" sort={{field: "created_at", order: "DESC" }} alwaysOn>
                              <SelectInput label={"Instruction"} optionText={(record: any) => `${moment(record.created_at).format("DD.MM.YY HH:mm")} – ${record.instructions_received?.[0]?.case_action?.case?.tenant?.name || ""} – ${record.user?.name || ""}`}/>
                          </ReferenceArrayInput>,
                          <SelectInput source="instruction@_eq" alwaysOn choices={toChoices([INSTRUCTION_PAY, INSTRUCTION_DO_NOT_PAY])}></SelectInput>,
                          <ReferenceInput source="case_action#case#tenant_id" reference="tenants" alwaysOn>
                              <AutocompleteInput filterToQuery={(searchText: string) => ({name: `${searchText}`})} label="Client"
                                                 fullWidth/>
                          </ReferenceInput>,
                      ]}
                      filter={invoiceFilter}
                      perPage={50}
                      pagination={<Pagination rowsPerPageOptions={[25, 50, 100, 200]} />}
                      empty={<HappyEmpty></HappyEmpty>}
                      exporter={invoiceExportToExcel}
                >
                    <Datagrid bulkActionButtons={<>
                        <MarkInvoiceSent></MarkInvoiceSent>
                        <BulkExportButton></BulkExportButton>
                    </>}>
                        {commonFields}
                        <ConfirmedField label="Confirmed"></ConfirmedField>
                        <DateField label="Completed/paid at" source={"case_action.completed_at"} showTime />
                        <ReferenceField reference="users" source={"case_action.completed_by"} label={"Completed by"} link={false}>
                            <TextField source={"name"}/>
                        </ReferenceField>
                        <TextField source={"case_action.completion_notes"} />
                    </Datagrid>
                </List>
            }
            {
                value === "invoice-history" &&
                <List {...props}
                      sort={{ field: "invoiced_at", order: "DESC" }}
                      filters={[
                          <ReferenceArrayInput label="Instruction" reference="instructions_received_event" source="instructions_received_event_id" sort={{field: "created_at", order: "DESC" }} alwaysOn>
                              <SelectInput label={"Instruction"} optionText={(record: any) => `${moment(record.created_at).format("DD.MM.YY HH:mm")} – ${record.instructions_received?.[0]?.case_action?.case?.tenant?.name || ""} – ${record.user?.name || ""}`}/>
                          </ReferenceArrayInput>,
                          <SelectInput source="instruction@_eq" alwaysOn choices={toChoices([INSTRUCTION_PAY, INSTRUCTION_DO_NOT_PAY])}></SelectInput>,
                          <ReferenceInput source="case_action#case#tenant_id" reference="tenants" alwaysOn>
                              <AutocompleteInput filterToQuery={(searchText: string) => ({name: `${searchText}`})} label="Client"
                                                 fullWidth/>
                          </ReferenceInput>,
                      ]}
                      filter={{
                          "instruction@_nin": [INSTRUCTION_WAIT, INSTRUCTION_DO_NOT_PAY],
                          "invoiced_at@_is_null": false,
                      }}
                      perPage={50}
                      pagination={<Pagination rowsPerPageOptions={[25, 50, 100, 200]} />}
                      empty={false}
                      exporter={invoiceExportToExcel}
                >
                    <Datagrid bulkActionButtons={<>
                        <BulkExportButton></BulkExportButton>
                    </>}>
                        {commonFields}
                        <NumberField source={"our_fee"}></NumberField>
                        <NumberField source={"agent_fee"}></NumberField>
                        <ConfirmedField label="Confirmed"></ConfirmedField>
                        <DateField label="Completed/paid at" source={"case_action.completed_at"} showTime />
                        <TextField source={"case_action.completion_notes"} />
                        <DateField label="Invoiced at" source={"invoiced_at"} showTime />
                        <ReferenceField reference="users" source={"invoiced_by"} label={"Invoiced by"} link={false}>
                            <TextField source={"name"}/>
                        </ReferenceField>
                    </Datagrid>
                </List>
            }
        </Page>
    );
};

const commonFields = [
    <TextField label="Client" source={"case_action.case.tenant.name"} ></TextField>,
    <FunctionField label={"Order #"} render={(record: any) => record?.instructions_received_event?.id?.split("-")[0]?.toUpperCase()} sortBy={"id"} />,
    <FunctionField
        label="Case ref"
        sx={{ textWrap: "nowrap" }}
        render={(record: any) => record ?
            <Link
                to={`/cases/${record?.case_action?.case?.id}/show`}
                target={"_blank"}
            >
                {record?.case_action?.case?.case_ref}
            </Link> : null
        }
    />,
    <TextField label={"Client ref"} source={"case_action.case.tenant_case_ref"} ></TextField>,
    <FunctionField
        label="Country"
        sx={{ textWrap: "nowrap" }}
        render={(record: any) => record?.case_action?.case?.country_code ?
            <Box><Flag countryCode={record.case_action.case.country_code === "up" ? "eu" : record.case_action.case.country_code}></Flag>{"  " + countries[record.case_action.case.country_code]}</Box> : null
        }
    />,
    <TextField source="case_action.case.application_number" label={"Application number"} />,
    <TextField source="case_action.case.registration_number" label={"Registration number"}/>,
    <TextField source="case_action.action_rule.action_name" label={"Annuity"} />,
    <DateField source="case_action.due_date" label={"Due date"} />,
    <DateField source="case_action.with_fine_due_date" label={"Grace date"} />,
    <TextField source={"instruction"} ></TextField>,
    <TextField source={"official_fee"}></TextField>,
    <TextField source={"our_fee"}></TextField>,
    <TextField source={"agent_fee"}></TextField>,
    <FunctionField
        label="Price"
        sx={{ textWrap: "nowrap" }}
        render={(record: any) => {
            const priceMismatch = record?.price !== record?.portal_price;
            return record.price ? `${record.price} ${record.price_currency}${priceMismatch ? " NB – Mismatch" : ""}` : "N/A";
        }}
    />,
    <TextField source={"portal_price"}></TextField>,
    <TextField source={"user.name"} label={"Instructed by"}></TextField>,
    <DateField label="Instructed at" source={"created_at"} showTime />,
];


const ConfirmedField = (props: any) => (
    <WrapperField label={"Confirmed"} {...props}>
        <DateField label="Confirmed at" source={"case_action.instructions_confirmed_by_payment_agent_at"} showTime sx={{ textWrap: "nowrap" }}/>
        <ReferenceField reference="users" source={"case_action.instructions_confirmed_by_payment_agent_by"} link={false}>
            <TextField source={"name"} sx={{ textWrap: "nowrap", display: "block" }} />
        </ReferenceField>
        <WithRecord render={(record: any) =>
            record?.confirmation_email_id &&
            <ShowEmailInDialogButton id={record.confirmation_email_id}></ShowEmailInDialogButton>
        }></WithRecord>
    </WrapperField>
);

const ShowEmailInDialogButton = (props: any) => {
    const id = props.id;

    if (!id) return null;

    return (
        <ShowInDialogButton
            fullWidth maxWidth="md" resource={"emails"} id={id}
            label={"View Email"}
            title={<WithRecord render={(record: any) => (
                <Box>{`Email sent ${moment(record.created_at).format("lll")}`}</Box>
            )}></WithRecord>}
        >

            <WithRecord render={(record: any) => (
                <DialogContent>
                    <Stack spacing={1} mb={2}>
                        <Box><strong>To:</strong> {record?.recipient_email}</Box>
                        <Box><strong>CC:</strong> {record?.recipient_cc_emails?.join(", ")}</Box>
                        <Box><strong>BCC:</strong> {record?.recipient_bcc_emails?.join(", ")}</Box>
                    </Stack>
                    <Box mb={5}><strong>Subject:</strong> {record?.subject}</Box>
                    <Box mb={2}><strong>Email:</strong></Box>
                    <div dangerouslySetInnerHTML={{ __html: record?.body }} />
                </DialogContent>
            )}/>
        </ShowInDialogButton>
    );
}
