

export const submitOrdersMutation = `
mutation submitOrders($orders: [orders_insert_input!]!) {
  insert_orders(objects: $orders) {
    affected_rows
  }
}
`


