import * as React from "react";
import { List, Datagrid, TextField, FunctionField, useRecordContext, Filter, DateInput, downloadCSV, TextInput, SelectInput, ReferenceInput, ReferenceManyField, ReferenceField, DateField, AutocompleteInput, WrapperField } from 'react-admin';
import {countries, countriesOptions} from "../../utils/countries";
import { Link } from "react-router-dom";
import { Button } from '@mui/material';
// @ts-ignore
import jsonExport from 'jsonexport/dist';
import moment from "moment";
import {useIsAdmin} from "../../auth/utils";
import {SubActionsList} from "./SubActionsList";
import {Page} from "../../utils/Page";
import {CountryInput} from "../../utils/CountryInput";
import {alternateBackground, CASE_FIELDS} from "../CaseList";
import { NextSubAction } from "../crud/CaseActionsList";
import {CaseRefField} from "../crud/CaseRefField";
import {actionTypeOptions} from "./utils";
import {CopyCaseButton} from "../CopyCaseButton";
import {CreateActionsButton} from "./CreateActionsButton";
import {FlagField} from "../../utils/FlagField";
import {useTenantContext} from "../../react-admin-overrides/AppLayout";
import { ShowContentInDialogButton } from "../../content/ShowContentInDialogButton";
export const dateFormat = "YYYY-MM-DD";
export const inputDateFormat = "YYYY-MM-DD";

export const capitalizeFirstLetter = (str: string) => str.charAt(0).toUpperCase() + str.slice(1)
const exportFields = [
    "country",
    "case_ref",
    "application_number",
    "registration_number",
    "action_name",
    "due_date",
    "our_fee",
    "our_fee_currency",
    "our_fee_nok",
    "official_fee",
    "official_fee_currency",
    "official_fee_nok",
    "agent_fee",
    "agent_fee_currency",
    "agent_fee_nok",
    "admin_fee",
    "total_price"
];

const exporter = (annuities: any[]) => {
    const annuitiesForExport = annuities.map((annuity) => {
        const annuityForExport: { [key: string]: any} = {};
        Object.keys(annuity)
            .filter((attribute) => exportFields.includes(attribute))
            .forEach((atttibute) => annuityForExport[atttibute] = annuity[atttibute]);

        annuityForExport.country = countries[annuity.country_code];
        annuityForExport.admin_fee = parseFloat((annuity.official_fee_nok * 0.05).toFixed(2));
        annuityForExport.total_price = Math.round(annuityForExport.admin_fee + annuity.official_fee_nok + annuity.our_fee_nok + annuity.agent_fee_nok);
        return annuityForExport;
    });
    jsonExport(annuitiesForExport, {
        headers: exportFields, // order fields in the export
        mapHeaders: (header: string) => capitalizeFirstLetter(header.replaceAll("_", ' '))
    }, (err:any, csv:any) => {
        downloadCSV(csv, `annuities-${moment().format(dateFormat)}`);
    });
};

const ActionsFilter = (props: any) => {
    const isAdmin = useIsAdmin();
    return (
        <Filter {...props}>
            <DateInput source="due_date@_gte" label="Due Date From" />
            <DateInput source="due_date@_lte" label="Due Date To" />
            <CountryInput source={"country_code"} label={"Country"}/>
            <TextInput label="Smart search" alwaysOn source="case_ref,tenant_case_ref,application_number,registration_number,applicant"></TextInput>
            <TextInput source="case_ref"></TextInput>
            <TextInput source="application_number"></TextInput>
            <TextInput source="registration_number"></TextInput>
            <SelectInput source="action_type" label={"Type"} choices={actionTypeOptions}/>
            {/*{*/}
            {/*    isAdmin &&*/}
            {/*    <ReferenceInput reference="tenants" source="tenant_id" label="Client">*/}
            {/*        <AutocompleteInput filterToQuery={(searchText: string) => ({name: `${searchText}`})} label={"Client"} />*/}
            {/*    </ReferenceInput>*/}
            {/*}*/}
            <ReferenceInput reference="users" source="responsible_user_id" label={"Responsible"}>
                <SelectInput label={"Responsible"}/>
            </ReferenceInput>
        </Filter>
    );
};

export const ActionsList = (props: any) => {
    const isAdmin = useIsAdmin();
    const tenant = useTenantContext();
    // const expand = isAdmin ? <SubActionsList/> : undefined;
    const expand = tenant?.tenant.feature_tasks || isAdmin ?  <SubActionsList/> : undefined;
    return (
        <Page title={"Actions"}>
            <List
                {...props}
                sort={{ field: "due_date", order: "ASC" }}
                filters={<ActionsFilter />}
                filter={{ "completed_at@_is_null": true }}
                exporter={isAdmin ? exporter : false}
            >
                <Datagrid bulkActionButtons={false} expand={expand} rowSx={alternateBackground}>
                    { isAdmin && <TextField source={"tenant_name"} label={"Client"}/> }
                    <FunctionField label={"Due Date"} render={(record: any) => {
                        return record?.inactivated_at ? <DateField source={"due_date"} sx={{ textDecoration: "line-through" }} title={`Inactivated, ${moment(record?.inactivated_at).format("lll")}`}/> : <DateField source={"due_date"} />;
                    }}></FunctionField>
                    <TextField source={"action_type"} label={"Type"} />
                    <TextField source={"action_name"} />
                    <CaseRefField source={CASE_FIELDS.CASE_REF} idSource={"case_id"}></CaseRefField>
                    {/*<TextField source={"country_code"} />*/}
                    <FlagField label={"Country"} source={"country_code"} sx={{ textWrap: "nowrap" }}></FlagField>
                    <TextField source={"application_number"} />
                    <TextField source={"registration_number"} />
                    <TextField source={"applicant"} />
                    <TextField source={"responsible_user_name"} label={"Responsible"}/>
                    <ReferenceManyField target={"case_action_id"} reference={"case_sub_actions"} label={"Next task"}>
                        <NextSubAction />
                    </ReferenceManyField>
                    <WrapperField label="Information">
                        <ShowContentInDialogButton label={"Show information"} />
                    </WrapperField>
                    <WrapperField label="Actions">
                        <SelectCountriesButton />
                    </WrapperField>
                </Datagrid>
            </List>
        </Page>
    )
};

const PCT_COUNTRY_CODE = "pc";
const EP_COUNTRY_CODE = "ep";
const PCT_SELECT_COUNTRIES_ACTION_CODE = "1300";
const PCT_SELECT_COUNTRIES_ACTION_CODE_2 = "1320";
const EP_SELECT_COUNTRIES_ACTION_CODE = "0800";
const EP_SELECT_COUNTRIES_ACTION_CODE_2 = "0700";
export const SelectCountriesButton = (props: any) => {
    const record = useRecordContext();
    console.log("select", record)
    if (!record) return null;
    if (record.country_code !== PCT_COUNTRY_CODE && record.country_code !== EP_COUNTRY_CODE) return null;

    // const priorityDate = record.first_priority_filing_date ? moment(record.first_priority_filing_date) : null;
    // const filingDate = record.filing_date ? moment(record.filing_date) : null
    // const date = priorityDate?.isBefore(filingDate) ? priorityDate : filingDate;
    // if (!date) return null;

    if (![PCT_SELECT_COUNTRIES_ACTION_CODE, PCT_SELECT_COUNTRIES_ACTION_CODE_2, EP_SELECT_COUNTRIES_ACTION_CODE, EP_SELECT_COUNTRIES_ACTION_CODE_2].includes(record.action_code)) return null;

    // const designatedStates = record.designated_states?.split(" ");
    // if (!designatedStates || designatedStates.length === 0) return null;

    return (
        <Button
            {...props}
            label={"Select countries"}
            component={Link}
            to={`/cases/${record.case_id}/select-countries`}
        >
            Select countries
        </Button>
    );
}

const CaseButton = () => {
    const annuity = useRecordContext();
    return (
        <Button
            component={Link}
            to={`/cases/${annuity?.case_id}/show`}
        >
            Case details
        </Button>
    );
};
