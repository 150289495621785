import {dateFormat} from "../actions/Actions";
// // @ts-ignore
//import * as XLSX from 'xlsx/xlsx.mjs';
import * as XLSX from 'xlsx-js-style';
import moment from "moment";
import {countries} from "../../utils/countries";

export type ExportConfiguration = Array<{ field: string, header?: string, cellOptions?: any, width?: number }>;

export const exportConfiguration: ExportConfiguration = [
    {field: "case_ref", header: "Case Ref",},
    {field: "tenant_case_ref", header: "Client Ref",},
    {field: "applicant", header: "Applicant",},
    {field: "country", header: "Country",},
    {field: "entity_size", header: "Entity size",},
    {field: "application_number", header: "Application number",},
    {field: "catchword", header: "Catchword",},
    {field: "invention_title", header: "Title",},
    {field: "application_type", header: "Application type",},
    {field: "based_on_pct", header: "Based on PCT",},
    {field: "filing_date", header: "Filing date",},
    {field: "international_filing_date", header: "International filing date",},
    {field: "registration_date", header: "Registration date",},
    {field: "registration_number", header: "Registration number",},
    {field: "status", header: "Status",},
    {field: "first_priority_filing_date", header: "First priority filing date",},
    {field: "priority_application_number", header: "Priority application number",},
    {field: "priority_country", header: "Priority country",},
    {field: "product", header: "Product",},
    {field: "department", header: "Department",},
    {field: "next_annuity", header: "Next annuity",}
];

export const downloadExcelPatents = (data: any[]) => {
    // const ws = XLSX.utils.json_to_sheet(data);
    const enrichedData = data.map((item) => ({
        ...item,
        country: countries[item?.country_code],
        priority_country: countries[item?.priority_country_code],
        next_annuity: item?.["next_maintenance_case_action"]?.[0]?.due_date,
    }));
    const arrayOfArrays = toArrayOfArrays(enrichedData, exportConfiguration);
    const ws = XLSX.utils.aoa_to_sheet(arrayOfArrays);

    ws["!cols"] = (arrayOfArrays?.[0] || []).map((header: any) => {
            const found = exportConfiguration.find((config) => config.header === header || config.field === header);
            return ({
                wch: found?.width || data.reduce((w, r) => Math.max(w, r?.[header]?.length || 0), header.length)
            });
        }
    );

    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Patents");
    XLSX.writeFile(wb, `breeze_patents${moment().format(dateFormat)}.xlsx`);
}

const toArrayOfArrays = (data: any[], config: ExportConfiguration) => {
    const headerLines = config.map((config) => {
        return config.header || config.field;
    });
    const contentLines = data.map((item) => {
        return config.map((config) => {
            if (config.cellOptions) {
                console.log("cellOptions", config.cellOptions, item[config.field]);
            }
            let colorOverride = {};
            if (config.field === "Breeze Comment" && item?.matchingResult?.status) {
                colorOverride = {
                    s: {
                        fill: {
                            fgColor: {
                                rgb:
                                    item.matchingResult.color === "success" ? "d6f3eb" :
                                    item.matchingResult.color === "warning" ? "feebcb" :
                                    item.matchingResult.color === "error" ? "FDE6E2" :
                                        "ffffff"
                            },
                        },
                        alignment: { wrapText: true }
                    }
                }
            }

            if (config.field === "Due Date Compare" && item?.["Due Date Compare"]) {
                colorOverride = {
                    s: {
                        fill: {
                            fgColor: {
                                rgb:
                                    item?.["Due Date Match"] === "success" ? "d6f3eb" :
                                        item?.["Due Date Match"] === "warning" ? "feebcb" :
                                                "ffffff"
                            },
                        },
                        alignment: { wrapText: true }
                    }
                }
            }

            if (config.field === "Grace Date Compare" && item?.["Grace Date Compare"]) {
                colorOverride = {
                    s: {
                        fill: {
                            fgColor: {
                                rgb:
                                    item?.["Grace Date Match"] === "success" ? "d6f3eb" :
                                        item?.["Grace Date Match"] === "warning" ? "feebcb" :
                                            "ffffff"
                            },
                        },
                        alignment: { wrapText: true }
                    }
                }
            }


            return config.cellOptions ? {
                v: item[config.field] || "",
                ...config.cellOptions,
                ...colorOverride
            } : item[config.field]
                //{
                //v: item[config.field] || "",
                //t: "s",
            //}
        });
    })
    return [
        headerLines,
        ...contentLines,
    ];
}