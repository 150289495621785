import {Box, Dialog, DialogContent, DialogTitle, Stack } from "@mui/material";
import Mustache from "mustache";
import {FunctionComponent, useState } from "react";
import { Button, useNotify, useDataProvider, useListContext, required, Form, useRefresh, useRecordContext } from "react-admin";
import { countries } from "../utils/countries";
import {
    DefaultEditorOptions,
    RichTextInput,
} from 'ra-input-rich-text';
import Table from '@tiptap/extension-table'
import TableCell from '@tiptap/extension-table-cell'
import TableHeader from '@tiptap/extension-table-header'
import TableRow from '@tiptap/extension-table-row'
import React from "react";
import {useTenantContext} from "../react-admin-overrides/AppLayout";
import {useUserId} from "../auth/utils";
import moment from "moment";
import {Flag, toFlagCode} from "../utils/Flag";
export const SendOrderConfirmationEmailButton = () => {
    const [open, setOpen] = useState(false);
    const [emailPreview, setEmailPreview] = useState("");
    const [emailData, setEmailData] = useState<any>(null);
    const dataProvider = useDataProvider();
    const notify = useNotify();
    const { tenant } = useTenantContext()  || {};
    const userId = useUserId();
    const refresh = useRefresh();
    const record = useRecordContext();


    const handleClick = async () => {
        try {
            const response = await dataProvider.getOne('email_templates', {
                id: record.order.order_type === "PCT" ? 'b4cffa6c-f46f-48bb-88a6-2b7848d93115' : "86276c7f-371f-41d5-b710-618505ceb3f5"
            });
            console.log(record);

            const sendingUserResponse = await dataProvider.getOne('users', {id: userId});
            // const recipientUsersResponse = await dataProvider.getOne('users', {
            //     id: record.order.created_by
            // });


            const cases = record.case?.national_phase_cases;
            const clientOrderRef = record?.user_reference;

            const htmlTable = `
                <table border="1" cellpadding="5" style="border-collapse: collapse; border: 1px;">
                    <tbody>
                        <tr>
                            <th>Country</th>
                            <th>Code</th>
                            <th>Applicant</th>
                            <th>Breeze Case Ref</th>
                            <th>Client Ref</th>
                        </tr>
                        ${cases.map((caseData: any) => `
                            <tr>
                                <td>
                                    <img 
                                        width="20" alt="flag"
                                        src="https://flagcdn.com/w40/${toFlagCode(caseData.country_code)}.png"
                                        >
                                    </img> 
                                    ${countries[caseData.country_code] || ''}
                                </td>
                                <td>${caseData.country_code?.toUpperCase() || ''}</td>
                                <td>${caseData?.applicant || ''}</td>
                                <td>${caseData?.case_ref || ''}</td>
                                <td>${caseData?.tenant_case_ref || ''}</td>
                            </tr>
                        `).join('')}
                    </tbody>
                </table>
            `;


            const submittedByUser = record?.order?.user;
            const recipientName = submittedByUser?.name;
            const applicationNumber = record?.case?.application_number;
            const registrationNumber = record?.case?.registration_number;
            const templateVariables = {
                table: htmlTable,
                recipientName,
                application_number: applicationNumber,
                registration_number: registrationNumber,
                senderName: sendingUserResponse.data?.name || "Breeze IP",
                clientOrderRef,
            };
            console.log(templateVariables);
            const emailPreview = Mustache.render(response.data?.body?.replaceAll("\n", "<div></div>") || '', templateVariables, {}, { escape: (text) => text });
            const emailSubject = Mustache.render(response.data?.subject || '', templateVariables);

            setEmailPreview(emailPreview);
            const recipientEmails = [submittedByUser?.email];
            const mainRecipient = recipientEmails[0];
            const otherRecipients = recipientEmails.slice(1);

            const ccEmails = [record?.order?.tenant?.cc_email];
            const recipientCcEmails = [...ccEmails, ...otherRecipients].filter((email: any) => mainRecipient !== email);
            const BREEZE_EMAIL = "post@breezeip.com";
            setEmailData({
                type: "INSTRUCTIONS_CONFIRMED_BY_BREEZE",
                sending_status: "INITIATED_BY_BREEZE",
                subject: emailSubject,
                body: emailPreview,
                recipient_email: mainRecipient,
                recipient_cc_emails: recipientCcEmails,
                recipient_bcc_emails: [mainRecipient, ...recipientCcEmails].includes(BREEZE_EMAIL) ? [] : [BREEZE_EMAIL],
            });
            setOpen(true);
        } catch (error) {
            console.error(error);
            notify('Error loading email preview', { type: 'error' });
        }
    };

    const handleSend = async (formData: any) => {
        console.log(emailData, formData);
        const now = moment();
        try {
            const { data: createdEmail } = await dataProvider.create('emails', {
                data: emailData
            });

            // Update all selected items with the confirmation email ID
            await dataProvider.updateMany('orders', {
                ids: [record?.order?.id],
                data: {
                    confirmed_at: now,
                    confirmed_by: userId,
                    confirmation_email_id: createdEmail.id
                }
            });

            // Update all corresponding case actions with confirmation timestamp
            // await dataProvider.updateMany('case_actions', {
            //     ids: selected.map(item => item.case_action_id),
            //     data: {
            //         instructions_confirmed_by_payment_agent_by: userId,
            //         instructions_confirmed_by_payment_agent_at: now,
            //         inactivated_by: userId,
            //         inactivated_at: now,
            //     }
            // });

            notify('Email sent successfully', { type: 'success' });
            setOpen(false);
            refresh();
        } catch (error) {
            notify('Error sending email', { type: 'error' });
        }
    };

    return (
        <>
            <Button
                label="Send Email and Confirm Instructions"
                onClick={handleClick}
            />
            
            <Dialog open={open} onClose={() => setOpen(false)} maxWidth="xl" fullWidth>
                <DialogTitle>Send confirmation email</DialogTitle>
                <DialogContent>

                    <Form onSubmit={handleSend}>
                        <Stack spacing={1} mb={2}>
                           <Box><strong>To:</strong> {emailData?.recipient_email}</Box>
                           <Box><strong>CC:</strong> {emailData?.recipient_cc_emails?.join(", ")}</Box>
                           <Box><strong>BCC:</strong> {emailData?.recipient_bcc_emails?.join(", ")}</Box>
                        </Stack>
                       <Box mb={5}><strong>Subject:</strong> {emailData?.subject}</Box>
                       <Box mb={2}><strong>Email:</strong></Box>
                        <div dangerouslySetInnerHTML={{ __html: emailPreview }} />
                        {/*<RichTextInput*/}
                        {/*    source="body"*/}
                        {/*    defaultValue={emailPreview}*/}
                        {/*    fullWidth*/}
                        {/*    label={false}*/}
                        {/*    validate={[required()]}*/}
                        {/*    editorOptions={{*/}
                        {/*       ...MyEditorOptions,*/}
                        {/*       onCreate: ({ editor }: { editor: any }) => {*/}
                        {/*           editorRef.current = editor;*/}
                        {/*       },*/}
                        {/*   }}*/}
                        {/*/>*/}

                        <Button
                            label="Send Confirmation Email"
                            type={"submit"}
                            sx={{ mt: 2 }}
                        />
                    </Form>
                </DialogContent>
            </Dialog>
        </>
    );
};

export const MyEditorOptions = {
    ...DefaultEditorOptions,
    extensions: [
        // @ts-ignore
        ...DefaultEditorOptions.extensions,
        Table,
        TableCell,
        TableHeader,
        TableRow
    ],
};
