import { Box } from '@mui/system';
import * as React from 'react';
import {FunctionComponent, useEffect} from "react";

export const PDFViewer: FunctionComponent<{ bucketFileName: string }> = (props) => {
    const [pdfUrl, setPdfUrl] = React.useState<string>();
    useEffect(() => {
        fetch(`${process.env.REACT_APP_FUNCTIONS_URL}/getDownloadUrl?bucketFilename=${props.bucketFileName}`)
            .then(r => r.json())
            .then((body) => setPdfUrl(body.url));
    }, []);

    //console.log(pdfUrl)
    return (
        <Box height={"1000px"}>
            <object width="100%" height={"100%"} data={pdfUrl} type="application/pdf"></object>
            {/**<iframe width="100%" height="100%" src={`https://view.officeapps.live.com/op/embed.aspx?src=${pdfUrl}`}></iframe>*/}
        </Box>
    );
}
