import { Stack } from '@mui/system';
import * as React from 'react';

export const TermsAndConditions = () => {
    return (
        <Stack justifyContent={"center"} alignItems={"center"} maxWidth={"800px"} margin={"auto"}>
            <h1>Terms and Conditions</h1>
            <ul style={{listStyleType: "none"}}>
                <li>1. Breeze IP searches official databases to collect information about the PCT cases and EP patents.
                    We cannot guarantee that the list of cases presented on the portal is complete, so please let us
                    know if cases are missing or if we have presented cases where you are not the correct owner.
                </li>
                <li>2. The due date for national filing of PCT applications is calculated based on the identification of
                    the first priority date, and the due date shown on the portal is 30 months from the first priority
                    due date. The due dates in some countries are 31 months from the first priority, but these due dates
                    are only shown when you have sent an order.
                </li>
                <li>3. The due date for validation/registration of granted EP patents is 3 months after the mention of
                    the grant.
                </li>
                <li>4. You must send your filing order in good time before the due date, at least one month before the
                    indicated due date.
                </li>
                <li>5. You will receive an automatic generated filing order receipt shortly after you have sent your
                    order. We will contact the selected local agents, and you will receive our formal order confirmation
                    for each of the countries within a few working days.
                </li>
                <li>6. We have to check whether our agents can handle the filings if you send your order less than 30
                    days before the due date. This will depend on the days left before filing, whether it is possible to
                    get term extensions for filing of translations, and the number of words to translate.
                </li>
                <li>7. If the PCT application or EP patent is not published in English, you must provide us with English
                    translations, and also confirm that the translations are exact translations of the published PCT
                    application or granted EP patent. The prices on the portal are based on publication of the PCT
                    application and EP application in English, and extra costs will be added for translation from
                    publication language to English.
                </li>
                <li>8. We will send you the invoice for your filing order shortly after receipt of your order, and the
                    invoice must be paid within the indicated payment date (which will depend on when the filing due
                    date is). We will not proceed with the filing of the national/regional applications or the payment
                    of annuities unless the invoice is paid as per our terms.
                </li>
                <li>9. The calculation of the filing costs is based on a number of different variables for each country
                    and for the given PCT applications, and we will do our best to present precise numbers. However, you
                    might experience deviations, and we will try to communicate these deviations shortly after receipt
                    of your order. The numbers of words are calculated automatically, and we ask you to check that the
                    numbers are in accordance with your estimates.
                </li>

                <li>10. The prices given for agents we do not have an agreement with, i.e. agents that are not indicated
                    as preferred on the portal, the costs are indicated as TBD (To Be Determined) or we give an estimate
                    based on standard price lists. We will do our best to ensure these prices, but changes may sometimes
                    occur.
                </li>

                <li>11. If you want to file voluntarily amended claims, you will be charged for this work in addition to
                    the estimate for filing of the application. In some countries we also have to translate amendments
                    filed under PCT Article 19 and 34 or the IPRP, and the costs for translation and filing of such
                    amendments are not included in the costs given on the portal.
                </li>
                <li>12. Designation of extension states in a European application will cost extra (not included in the
                    estimate).
                </li>
                <li>13. Estimates for the national or regional application do not include renewal fees which may in some
                    countries be due shortly after filing. We will pay annuities if they are due at or shortly after
                    filing, and they will be charges separately. We will do our best to inform you about annuity dates,
                    but you are responsible for payment of annuities unless you instruct Anaqua Foreign Filing to
                    monitor and pay annuities as part of the order.
                </li>
                <li>14. The prices indicated on the portal do not include the filing of a request for substantive
                    examination. If the substantive examination has to requested at filing, we will charge you for this
                    request separately.
                </li>
                <li>15. We will provide you with filled-in documents (Power of Attorney and assignment documents)
                    shortly after receipt of your filing order, and you must send us the signed documents so that we can
                    file the documents in good time before the respective due dates. Failure to send the documents can
                    result in loss of the application, and if you send the documents too late our local agent will
                    charge you extra. If original documents are needed, you have to send these directly to our local
                    agents. We will provide you with contact details.
                </li>
                <li>16. Breeze IP accepts no responsibility for errors or delays perpetrated by national patent
                    authorities or other authorities.
                </li>
                <li>17. Breeze IP does not assume responsibility for any errors or delays perpetrated by our cooperation
                    partners that we have to use for foreign filing of applications. The local partners are engaged on
                    behalf of our client, and are only mediated by Breeze IP.
                </li>
                <li>18. Breeze IP accepts no responsibility for loss of rights caused by stoppage or delays of
                    transactions in connection with countries, companies or people that are on any government, bank or
                    financial authorities’ sanctions lists.
                </li>
                <li>19. Please note that if your order is sent less than 5 business days before the deadline we cannot
                    guarantee that the order will be handled. Our confirmation does not constitute an acceptance and
                    only confirms receipt of the order. We will inform you when the local agent has accepted the order.
                </li>
            </ul>
        </Stack>
    );
}