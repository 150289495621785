import * as React from "react";
import { Suspense } from "react";
import { List, Datagrid, Edit, Create, SimpleForm, TextField, EditButton, TextInput, RichTextField} from 'react-admin';
import {useTenantId} from "../data/useTenantId";
import {LazySpinner, Spinner} from "../utils/Spinner";

const RichTextInput = React.lazy(() =>
    import('ra-input-rich-text').then(module => ({
        default: module.RichTextInput,
    }))
);

export type Content = {
    id: string;
    created_at: string;
    updated_at: string;
    title: string;
    html_content: string;
}

enum CONTENT_FIELDS {
    ID = "id",
    CREATED_AT = "created_at",
    UPDATED_AT = "updated_at",
    TITLE = "title",
    HTML_CONTENT = "html_content",
}

export const ContentList = (props: any) => (
    <List {...props} sort={{ field: CONTENT_FIELDS.TITLE, order: "ASC" }}>
        <Datagrid bulkActionButtons={false}>
            <TextField source={CONTENT_FIELDS.TITLE} />
            <RichTextField source={CONTENT_FIELDS.HTML_CONTENT} />
            <EditButton />
        </Datagrid>
    </List>
);

const Title = ({ record }: { record: Content }) => {
    return <span>Title: {record ? `"${record.title}"` : ''}</span>;
};

export const ContentEdit = (props: any) => {
    const tenantId = useTenantId();
    return (
        <Edit title={<Title record={props.record} />} redirect={"list"} {...props}>
            <Suspense fallback={<LazySpinner delay={500} />}>

            <SimpleForm maxWidth={"sm"}>
                <TextInput disabled source="id" />
                <TextInput source={CONTENT_FIELDS.TITLE} />
                <RichTextInput source={CONTENT_FIELDS.HTML_CONTENT} />
            </SimpleForm>
            </Suspense>
        </Edit>
    );
};

export const ContentCreate = (props: any) => {
    const tenantId = useTenantId();
    return (
        <Create title="Create an email template" redirect={"list"} {...props}>
            <Suspense fallback={<LazySpinner delay={500} />}>
            <SimpleForm maxWidth={"sm"}>
                <TextInput source={CONTENT_FIELDS.TITLE} />
                <RichTextInput source={CONTENT_FIELDS.HTML_CONTENT} />
            </SimpleForm>
            </Suspense>
        </Create>
    );
};
